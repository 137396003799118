/** @format */

import React from "react";
import axios from "axios";
import { Button, CircularProgress } from "@mui/material";

const CreateEventNortic = ({ payload, setTicketEventId }) => {
  const [loading, setLoading] = React.useState(false);
  // React Component to send payload
  const sendEvent = async () => {
    setLoading(true);

    // const payload = {
    //   organizerId: 3302,
    //   name: "Metallica",
    //   status: "DRAFT",
    //   eventCategory: "THEATER",
    //   shows: [
    //     {
    //       name: "Shrek",
    //       startDate: "2025-01-21T14:42:30+01:00",
    //       maxAvailable: 1000,
    //       ticketCategories: [
    //         {
    //           name: "Ordinarie",
    //           price: 100,
    //           maxAvailable: 1000,
    //         },
    //       ],
    //     },
    //   ],
    // };
    console.log("Create new Event Nortic", payload);
    try {
      const response = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/createEventNortic", payload);
      console.log("Event created successfully:", response.data);
      setLoading(false);
      setTicketEventId(response.data.id);
    } catch (error) {
      console.error("Error creating event:", error);
      setLoading(false);
    }
  };

  return (
    <Button onClick={sendEvent} variant="contained" size="small">
      {loading && <CircularProgress style={{ zoom: "0.4" }} />}Create Event
    </Button>
  );
};

// Example JSON payload to be sent in the request:
// {
//   "organizerId": 1,
//   "name": "Metallica",
//   "status": "DRAFT",
//   "eventCategory": "THEATER",
//   "shows": [
//     {
//       "name": "string",
//       "startDate": "2025-01-21T14:42:30+01:00",
//       "maxAvailable": 1000,
//       "ticketCategories": [
//         {
//           "name": "string",
//           "price": 100,
//           "maxAvailable": 1000
//         }
//       ]
//     }
//   ]
// }

export default CreateEventNortic;

// {
//   "organizerId": 3305,
//   "name": "Nikon DF",
//   "status": "DRAFT",
//   "eventCategory": "THEATER",
//   "shows": [
//       {
//           "name": "Nikon DF",
//           "startDate": "2024-12-13T19:00:00+01:00",
//           "maxAvailable": 550,
//           "ticketCategories": [
//               {
//                   "id": 53299,
//                   "name": "Barstolar",
//                   "sortWeight": 0,
//                   "organizerId": 3305,
//                   "price": 295,
//                   "selected": true,
//                   "maxAvailable": 300
//               },
//               {
//                   "id": 53300,
//                   "name": "Barts",
//                   "sortWeight": 1,
//                   "organizerId": 3305,
//                   "selected": true,
//                   "maxAvailable": 200,
//                   "price": 300
//               },
//               {
//                   "id": 53298,
//                   "name": "Biostolar",
//                   "sortWeight": 0,
//                   "organizerId": 3305,
//                   "price": 500,
//                   "selected": true,
//                   "maxAvailable": 50
//               }
//           ]
//       }
//   ]
// }
// const payload = {
//   organizerId: 3302,
//   name: "Metallica",
//   status: "DRAFT",
//   eventCategory: "THEATER",
//   shows: [
//     {
//       name: "Shrek",
//       startDate: "2025-01-21T14:42:30+01:00",
//       maxAvailable: 1000,
//       ticketCategories: [
//         {
//           name: "Ordinarie",
//           price: 100,
//           maxAvailable: 1000,
//         },
//       ],
//     },
//   ],
// };
