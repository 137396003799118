/** @format */

import React, { useState, useEffect } from "react";
import { getFirestore, collection, getDocs, doc, query } from "firebase/firestore";
import { Button, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import storeCurrentVenue from "../features/venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { orderBy, limit } from "firebase/firestore";
import ObjectSize from "../helpers/ObjectSize";

const ModulesBackup = ({ additionals, setAdditionals }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateOfBackup, setDateOfBackup] = useState("");
  const [selectedPost, setSelectedPost] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [targetVenue, setTargetVenue] = useState(null);
  const [targetModule, setTargetModule] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const db = getFirestore();
        const backupManagerDocRef = doc(db, "BackupManager", "drx7xhs6lvp5qsbsgmr37wqxynzvnqmg");

        const backupsCollectionRef = collection(backupManagerDocRef, "Backups");

        const querySnapshot = await getDocs(backupsCollectionRef);
        const postsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setPosts(postsData);
        setLoading(false);
        console.log("Posts fetched: ", postsData);
      } catch (err) {
        console.error("Error fetching posts: ", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading backups...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  {
    console.log("POSTS:", posts);
  }

  async function sendModule() {
    console.log("Sending module", selectedModule);
    console.log("currentVenue", currentVenue);

    let tmpCurrentVenue = JSON.parse(JSON.stringify(currentVenue));
    // let tmpAdditionals=JSON.parse(JSON.stringify(additionals))
    tmpCurrentVenue.features[selectedModule.additionalId] = selectedModule;
    console.log("tmpCurrentVenue", tmpCurrentVenue);

    setAdditionals(tmpCurrentVenue.features);
  }

  async function sendOption() {
    console.log("Sending option", selectedOption);
    console.log("currentVenue", currentVenue);
    console.log("targetModule", targetModule);

    let tmpCurrentVenue = JSON.parse(JSON.stringify(currentVenue));
    // let tmpAdditionals=JSON.parse(JSON.stringify(additionals))
    tmpCurrentVenue.features[targetModule.additionalId].options[selectedOption.optionId] = selectedOption;
    console.log("tmpCurrentVenue", tmpCurrentVenue);

    setAdditionals(tmpCurrentVenue.features);
  }

  if (posts)
    return (
      <div style={{ background: "#00ffd411", padding: "20px" }}>
        <h7>Backup size:</h7>
        <span>
          <ObjectSize dataObject={posts && posts} />
        </span>
        {/* <Select onChange={(e) => setTargetModule(e.target.value)}> */}
        {additionals && (
          <>
            <h7>Target Venue Modules</h7>
            <Select onChange={(e) => setTargetModule(e.target.value)}>
              {Object.values(additionals).map((module) => (
                <MenuItem value={module}>{module.additional}</MenuItem>
              ))}
            </Select>
          </>
        )}
        {console.log("Target Venue", targetVenue)}
        <h2>Backup Posts</h2>
        <h1>{dateOfBackup}</h1>
        <>
          <h7>Backups</h7>
          <Select onChange={(e) => setSelectedPost(e.target.value)}>
            {posts.map((post) => (
              <MenuItem key={post.id} value={post}>
                {post.id}
              </MenuItem>
            ))}
          </Select>
          {selectedPost && (
            <>
              <h7>Modules</h7>
              <Select onChange={(e) => setSelectedModule(e.target.value)}>
                {Object.values(selectedPost.modules).map((module) => (
                  <MenuItem value={module}>
                    {module.additional}({Object.values(module.options).length})
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
          {selectedModule && (
            <>
              {" "}
              <h7>Options</h7>
              <Select onChange={(e) => setSelectedOption(e.target.value)}>
                {Object.values(selectedModule.options).map((option) => (
                  <MenuItem value={option}>{option.option}</MenuItem>
                ))}
              </Select>
            </>
          )}
          {selectedOption && console.log("Selected Option", selectedOption)}
        </>
        {selectedModule && (
          <div>
            Send module:{" "}
            <Button variant="outlined" onClick={() => sendModule()}>
              {selectedModule.additional}
            </Button>{" "}
            to => venue: <h7>{currentVenue.venue}</h7>
          </div>
        )}
        {selectedOption && targetModule && (
          <div>
            Send option:{" "}
            <Button variant="outlined" onClick={() => sendOption()}>
              {selectedOption.option}
            </Button>{" "}
            to => venue: <h7>{currentVenue.venue}</h7>module: <h7>{targetModule.additional}</h7>
          </div>
        )}
      </div>
    );
};

export default ModulesBackup;
