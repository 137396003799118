/** @format */

import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MailingListOutput = () => {
  const [emailAdresses, setEmailAdresses] = useState([]);
  // Create a component that will display the mailing list output from the collection MailingList in the database.
  // The component should display the following fields: email, name, and dateJoined.
  // The component should display the data in a table format.
  async function mailingListOutput() {
    const mailingListRef = collection(db, "MailingList");
    const querySnapshot = await getDocs(mailingListRef);
    const mailingListData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Mailing List: ", mailingListData);
    setEmailAdresses(mailingListData.map((item) => item.email));
    return mailingListData;
  }
  const mailingListData = mailingListOutput();
  console.log("Mailing List: ", mailingListData);

  return (
    <div>
      <Accordion expandIcon={<ExpandMoreIcon />}>
        <AccordionSummary>MailingListOutput</AccordionSummary>
        <AccordionDetails>
          {emailAdresses &&
            emailAdresses.map((email) => (
              <div key={email}>
                <p>{email}</p>
              </div>
            ))}
          {/* {mailingListData &&
        mailingListData.map((item) => (
          <div key={item.id}>
            <p>{item.email}</p>
            <p>{item.name}</p>
            <p>{item.dateJoined}</p>
          </div>
        ))} */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default MailingListOutput;
