/** @format */

import React, { useEffect, useState } from "react";
import { Slider, Box, Typography } from "@mui/material";
import { set } from "date-fns";

const MonthRangeSlider = ({ minYear = 2020, maxYear = 2025, setDateRange }) => {
  // Calculate the total range
  const monthsPerYear = 12;
  const minValue = minYear * monthsPerYear + 1; // 2020 January
  const maxValue = maxYear * monthsPerYear + monthsPerYear; // 2025 December

  const [range, setRange] = useState([minValue, maxValue]);

  // Format the value to display "Year - Month"
  const formatLabel = (value) => {
    const year = Math.floor((value - 1) / monthsPerYear);
    const month = ((value - 1) % monthsPerYear) + 1;
    console.log("year", year, "month", month);
    return `${year}-${month.toString().padStart(2, "0")}`;
  };

  const handleChange = (event, newRange) => {
    setRange(newRange);
    // console.log("newRange", formatLabel(range[0] + 1), formatLabel(range[1] + 1));
  };

  useEffect(() => {
    setDateRange([formatLabel(range[0]), formatLabel(range[1])]);
  }, [range]);

  return (
    <Box sx={{ width: 400, margin: "20px auto", textAlign: "center" }}>
      <Typography variant="h6">Month and Year Range</Typography>
      <Typography>
        {formatLabel(range[0])} - {formatLabel(range[1])}
      </Typography>
      <Slider
        value={range}
        onChange={handleChange}
        valueLabelDisplay="auto"
        valueLabelFormat={formatLabel} // Custom labels
        min={minValue}
        max={maxValue}
        step={1}
        marks
        sx={{
          mt: 3,
        }}
      />
    </Box>
  );
};

export default MonthRangeSlider;
