/** @format */

import React, { useEffect, useState } from "react";
import GradingIcon from "@mui/icons-material/Grading";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReplyIcon from "@mui/icons-material/Reply";
import { set } from "date-fns";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import BatchAgreementModal from "./BatchAgreementModal";
import { CircularProgress, MenuItem } from "@mui/material";

const AgreementStatusLink = ({ booking }) => {
  const [agreement, setAgreement] = useState({});
  const [agreementStatus, setAgreementStatus] = useState("");
  const [showAgreement, setShowAgreement] = useState(false);

  useEffect(() => {
    // getAgreement();
  }, []);

  //   async function getAgreement() {
  //     const snap = await getDoc(doc(db, "Agreements", agreementId));
  //     setAgreement(snap.data());
  //     setAgreementStatus(snap.data().status);
  //   }

  const handleContextMenu = (event) => {
    event.preventDefault(); // Viktigt: Hindrar standardmenyn från att visas
    console.log("Högerklick!");

    // Här kan du visa din anpassade meny
    const menu = document.createElement("div");
    menu.style.position = "absolute";
    menu.style.left = `${event.clientX}px`;
    menu.style.top = `${event.clientY}px`;
    menu.innerHTML = `
        <div></div>
    `;
    document.body.appendChild(menu);

    // Ta bort menyn när man klickar någon annanstans
    const handleClick = () => {
      document.body.removeChild(menu);
      document.removeEventListener("click", handleClick);
    };
    document.addEventListener("click", handleClick);
  };

  return (
    <div>
      <div onClick={() => setShowAgreement(true)} style={{ position: "relative", cursor: "pointer" }} onContextMenu={handleContextMenu}>
        {booking.status === "Pending Request" ? (
          <> {!showAgreement && <AccessTimeIcon />}</>
        ) : booking.status === "Awaiting Artist" ? (
          <> {!showAgreement && <ReplyIcon />}</>
        ) : booking.status === "Confirmed" ? (
          <> {!showAgreement && <GradingIcon />}</>
        ) : null}
        {showAgreement && <CircularProgress style={{ zoom: 0.6 }} className="circleTheIcon" />}
      </div>
      {showAgreement && (
        <BatchAgreementModal
          agreementId={booking.agreementId}
          setShowAgreement={setShowAgreement}
          open={showAgreement}
          sign={
            booking.status === "Pending Request"
              ? "venueSignature"
              : booking.status === "Awaiting Artist"
              ? ""
              : booking.status === "Confirmed"
              ? ""
              : ""
          }
        />
      )}
    </div>
  );
};

export default AgreementStatusLink;
