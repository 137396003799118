/** @format */

import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { collection, getDoc, getDocs, doc } from "firebase/firestore";

import { Button, CircularProgress, Grid, Slider, Switch } from "@mui/material";

import VisualDataSummaryBooking from "../components/VisualData/VisualDataSummaryBooking";
import EventTravelData from "../features/EventTravelData";

import ObjectSize from "./ObjectSize";
import { fetchCachedData } from "../components/Sidebar/IndexedDBForCurrentVenueBookings";
import GraphForTicketSales from "../components/UI helpers/GraphForTicketSales";
import EventTravelDataExtended from "../features/EventTravelDataExtended";
import { useSelector } from "react-redux";
import { makeNumber, numberFormatted } from "./Numbers";
import MonthRangeSlider from "../components/UI helpers/MonthRangeSlider";
import UpCount from "../components/UI helpers/UpCount";
import { set } from "date-fns";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import PdfHandler from "../components/Documents/PdfHandler";
import PdfHandlerGeneral from "../components/Documents/PdfHandlerGeneral";

const TravelReport = () => {
  const [currentVenue, setCurrentVenue] = useState({
    venueid: "drx7xhs6lvp5qsbsgmr37wqxynzvnqmg",
  });
  const [currentVenueBookings, setCurrentVenueBookings] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [totalImpact, setTotalImpact] = useState(0);
  const ticketVenueId = "DCHMLTF6FCWGXMP";
  const cumulativeImpact = useSelector((state) => state.counter.cumulativeImpact);
  const [impact, setImpact] = useState(0);
  const [impactArray, setImpactArray] = useState([]);
  const [totImpact, setTotImpact] = useState(0);
  const [totVisitors, setTotVisitors] = useState(0);
  const [totDistance, setTotDistance] = useState(0);
  const [minYear, setMinYear] = useState(0);
  const [maxYear, setMaxYear] = useState(0);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [calculateRange, setCalculateRange] = useState(false);
  const [bicycleShare, setBicycleShare] = useState(0);
  const [carShare, setCarShare] = useState(0);
  const [busShare, setBusShare] = useState(0);
  const [trainShare, setTrainShare] = useState(0);
  const [planeShare, setPlaneShare] = useState(0);
  const [bicycleDistance, setBicycleDistance] = useState(0);
  const [carDistance, setCarDistance] = useState(0);
  const [busDistance, setBusDistance] = useState(0);
  const [trainDistance, setTrainDistance] = useState(0);
  const [planeDistance, setPlaneDistance] = useState(0);
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [loading, setLoading] = useState(false);

  let cumArray = [];

  const addOneMonth = (dateString) =>
    new Date(dateString + "-01").setMonth(new Date(dateString + "-01").getMonth() + 1) &&
    new Date(new Date(dateString + "-01").setMonth(new Date(dateString + "-01").getMonth() + 1)).toISOString().slice(0, 7);

  // Get venue data
  useEffect(() => {
    async function getVenueData() {
      try {
        // Fetch venue data
        const snap = await getDoc(doc(db, "Venues", currentVenue.venueid));
        const venueData = snap.data();
        setCurrentVenue(venueData);

        // Fetch cached bookings
        const cachedBookings = await fetchCachedData(currentVenue.venueid);
        if (cachedBookings.length > 0) {
          setCurrentVenueBookings(cachedBookings);
        }

        // Fetch purchases
        const purchasesCollectionRef = collection(db, `Tickets/${ticketVenueId}/Purchases`);
        const purchasesSnapshot = await getDocs(purchasesCollectionRef);
        const fetchedPurchases = purchasesSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPurchases(fetchedPurchases);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    getVenueData();
  }, [currentVenue.venueid, ticketVenueId]);

  useEffect(() => {
    if (!cumulativeImpact.length) return;
    // setTotalImpact(cumulativeImpact.reduce((acc, item) => acc + item, 0));
    console.log("cumulativeImpact: ", cumulativeImpact);
  }, [cumulativeImpact]);

  useEffect(() => {
    console.log("cumArray: ", impact);

    // setTotalImpact((prev) => prev + impact);
  }, [impact]);

  useEffect(() => {
    if (!impactArray.length) return;
    // alert("impactArray: " + impactArray.length);
    console.log("impactArray: ", impactArray);
    // setImpact(impactArray.reduce((acc, item) => acc + item.impact, 0));
    setTotVisitors(impactArray.reduce((acc, item) => acc + item.visitors, 0));
    setTotImpact(impactArray.reduce((acc, item) => acc + makeNumber(item.climateImpact), 0));
    setBicycleShare(impactArray.reduce((acc, item) => acc + makeNumber(item.bicycleShare), 0) / impactArray.length);
    setCarShare(impactArray.reduce((acc, item) => acc + makeNumber(item.carShare), 0) / impactArray.length);
    setBusShare(impactArray.reduce((acc, item) => acc + makeNumber(item.busShare), 0) / impactArray.length);
    setTrainShare(impactArray.reduce((acc, item) => acc + makeNumber(item.trainShare), 0) / impactArray.length);
    setPlaneShare(impactArray.reduce((acc, item) => acc + makeNumber(item.planeShare), 0) / impactArray.length);
    setBicycleDistance(impactArray.reduce((acc, item) => acc + makeNumber(item.bicycleDistance), 0));
    setCarDistance(impactArray.reduce((acc, item) => acc + makeNumber(item.carDistance), 0));
    setBusDistance(impactArray.reduce((acc, item) => acc + makeNumber(item.busDistance), 0));
    setTrainDistance(impactArray.reduce((acc, item) => acc + makeNumber(item.trainDistance), 0));
    setPlaneDistance(impactArray.reduce((acc, item) => acc + makeNumber(item.planeDistance), 0));
    setLoading(false);
  }, [impactArray]);

  useEffect(() => {
    setLoading(true);
    if (!currentVenueBookings.length) return;
    // Sort the array by date
    const sortedBookings = currentVenueBookings.sort((a, b) => new Date(a.date) - new Date(b.date));

    // Get the first and last dates
    const firstDate = sortedBookings[0].date;
    const lastDate = sortedBookings[sortedBookings.length - 1].date;

    setMinYear(new Date(firstDate).getFullYear());
    setMaxYear(new Date(lastDate).getFullYear());
  }, [currentVenueBookings]);

  // useEffect(() => {
  //   function filterByDateRange(bookings, startDate, endDate) {
  //     return bookings.filter((booking) => {
  //       const bookingDate = new Date(booking.date);
  //       return bookingDate >= startDate && bookingDate <= endDate;
  //     });
  //   }
  //   setFilteredBookings(filterByDateRange(currentVenueBookings, new Date(dateRange[0]), new Date(dateRange[1])));
  //   console.log("dateRange: ", dateRange);
  // }, [dateRange]);

  useEffect(() => {
    if (calculateRange === true) return;
    setTotImpact(0);
    setTotVisitors(0);
    setImpactArray([]);
    setImpact(0);
  }, [calculateRange]);

  const [pdfUrl, setPdfUrl] = useState("");
  const [includedAttachments, setIncludedAttachments] = useState([]);
  const agreement = { agreementId: "-" };
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      {console.log("PDF", document.querySelector("#report"))}
      <PdfHandlerGeneral
        pdfDoc={document.querySelector("#report") && document.querySelector("#report").innerHTML}
        setPdfUrl={setPdfUrl}
        includedAttachments={includedAttachments}
        // agreementId={bookingData.agreementId}
        agreement={agreement}
      />
      <Button onClick={() => setCalculateRange(!calculateRange)} variant="outlined">
        {calculateRange ? "Close" : "Calculate range"}
      </Button>
      {loading && <CircularProgress style={{ zoom: "0.4", marginLeft: "20px" }} />}
      <div id="report" className="sustainabilityReport">
        <Grid container mt={8} className="reportData">
          <h1>Venue Report</h1>
          <MonthRangeSlider minYear={minYear} maxYear={maxYear} setDateRange={setDateRange} />

          {/* <h4>Total distance traveled: {numberFormatted(totDistance)}</h4> */}

          {/*  <div>ticketVenueId: {ticketVenueId}</div>
      {purchases.length > 0 && (
        <div>
          purchases: <ObjectSize dataObject={purchases} />
        </div>
      )}
     <div>
        currentVenue: <ObjectSize dataObject={currentVenue} />
      </div>
      <div>
        currentVenueBookings: <ObjectSize dataObject={currentVenueBookings} />
      </div> */}
          <Grid item xs={12}>
            <h3>Premises</h3>
          </Grid>
          <Grid style={{ width: "90%" }}>
            <VisualDataSummaryBooking venueid={currentVenue.venueid} />
          </Grid>

          <Grid style={{ width: "90%" }} mt={4}>
            <h3>
              Travel Data{" "}
              {dateRange && (
                <>
                  between {dateRange[0]} and {dateRange[1]}{" "}
                </>
              )}
            </h3>
            <table className="sustainabilityReport">
              <tr>
                <td>Total CO2e impact:</td>{" "}
                <td>
                  <UpCount target={totImpact} type="kg" />
                </td>
              </tr>
              <tr>
                <td>Total visitors:</td>
                <td>
                  <UpCount target={totVisitors} type="" />
                </td>
              </tr>
              <tr>
                <td>Average impact:</td>
                <td>
                  <UpCount target={totImpact / totVisitors} type="kg" />
                  {/* {numberFormatted(totImpact / totVisitors)} kg */}
                </td>
              </tr>
              <tr>
                <td>
                  <Switch onChange={() => setShowMap(!showMap)} />
                  <label>Show map</label>
                </td>
              </tr>
              <tr className="headerIcons" style={{ border: "none", padding: "10px 0px" }}>
                <th></th>
                <th>
                  <DirectionsCarIcon style={{ fontSize: "35px", fill: "#ccc" }} />
                </th>
                <th>
                  <DirectionsBikeIcon style={{ fontSize: "35px", fill: "#ccc" }} />
                </th>
                <th>
                  <DirectionsBusIcon style={{ fontSize: "35px", fill: "#ccc" }} />
                </th>
                <th>
                  <DirectionsRailwayIcon style={{ fontSize: "35px", fill: "#ccc" }} />
                </th>
                <th>
                  <AirplanemodeActiveIcon style={{ fontSize: "35px", fill: "#ccc" }} />
                </th>
                <th></th>
              </tr>
              <tr className="travelImpact">
                <td>Total Distance</td>
                <td>
                  <UpCount target={carDistance} type="km" />
                </td>
                <td>
                  <UpCount target={bicycleDistance} type="km" />
                </td>
                <td>
                  <UpCount target={busDistance} type="km" />
                </td>
                <td>
                  <UpCount target={trainDistance} type="km" />
                </td>
                <td>
                  {/* {planeDistance} */}
                  <UpCount target={planeDistance} type="km" />
                </td>
                <td></td>
              </tr>
              <tr className="travelImpact">
                <td>Use of transport</td>
                <td>
                  <UpCount target={carShare} type="%" />
                </td>
                <td>
                  <UpCount target={bicycleShare} type="%" />
                </td>
                <td>
                  <UpCount target={busShare} type="%" />
                </td>
                <td>
                  <UpCount target={trainShare} type="%" />
                </td>
                <td>
                  {/* {planeShare} */}
                  <UpCount target={planeShare} type="%" />
                </td>
                <td></td>
              </tr>
            </table>
          </Grid>

          <Grid
            item
            gap={1}
            mt={4}
            // style={{ height: "50vh", overflow: "scroll" }}
          >
            {dateRange && <>{end}</>}
            {calculateRange && (
              <>
                {currentVenueBookings
                  .filter((bookingData) => {
                    const bookingDate = new Date(bookingData.date); // Replace 'date' with the correct property name
                    return bookingDate >= new Date(dateRange[0]) && bookingDate <= new Date(addOneMonth(dateRange[1]));
                  })
                  .map((bookingData) => (
                    <Grid
                      item
                      xs={12}
                      style={{ width: "100vw", display: "grid", gap: "15px" }}
                      className={bookingData.status !== "Killed" ? "page-break" : ""}
                    >
                      {console.log("impact", impact, "bookingData", bookingData)}
                      {bookingData.ticketEventId !== "-" && (
                        <Grid item style={{ padding: "5px" }}>
                          <EventTravelDataExtended
                            key={bookingData.id}
                            booking={bookingData}
                            impact={impact}
                            setImpact={setImpact}
                            impactArray={impactArray}
                            setImpactArray={setImpactArray}
                            // setDateRange={setDateRange}
                            showMap={showMap}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TravelReport;
