/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import { Button, CircularProgress } from "@mui/material";
import { wait } from "../MiscUIComponents/Wait";
// import { db, doc } from "../../firebase";
import { collection, doc, updateDoc } from "firebase/firestore";
import { set } from "date-fns";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { db } from "../../firebase";
import { Download } from "@mui/icons-material";

function PdfHandlerGeneral({ pdfDoc, setPdfUrl, includedAttachments, pdfName = "venuehub" }) {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  let link = document.createElement("a");
  // const [link, setLink] = useState(null);
  const storage = getStorage();

  async function handlePDF() {
    setLoading(true);
    const htmlData = {
      html: `
        <style>
            .page-break {
            page-break-before: always;
            }
            .reportData .sustainabilityReport {
            transform: scale(1);
            border: 1px solid #444;
            padding: 5px;
            color: #666;
            width: 100%;
            font-size: 17px;
            }
            svg {
            overflow: visible;
            }

            .sustainabilityReport th {
            padding: 10px 5px 10px 4px !important;
            }
            .sustainabilityReport th svg {
            padding: 0px 10px 0px 15px !important;
            }

            .sustainabilityReport {
            transform: scale(1);
            border: 1px solid #444;
            padding: 5px;
            color: #666;
            width: 100%;
            font-size: 12px;
            font-family: arial;
            }

            .sustainabilityReport table {
            opacity: 0.9;
            width: 100%;
            }

            .sustainabilityReport table td {
            color: rgb(0, 0, 0) !important;
            }

            .sustainabilityReport tr {
            border-bottom: 1px solid #444;
            }

            .sustainabilityReport th {
            color: #666;
            padding: 0px 5px;
            }

            .sustainabilityReport td {
            padding: 0px 5px;
            }

            .headerIcons {
            text-align: center;
            zoom: 1;
            font-size: 20px;
            fill: rgb(204, 204, 204) !important;
            }
            .headerIcons svg {
            height: 50px;
            }

            .sustainabilityReport .travelImpact {
            text-align: center;
            }

            .vdPie {
            zoom: 0.6;
            display: flex;
            justify-content: center;
            align-items: center;
            }

            .travelReport {
            text-align: center;
            position: absolute;
            bottom: 1px;
            width: 100%;
            background: #000000bb;
            backdrop-filter: blur(5px);
            }

            .travelReport td {
            color: #00ffd499;
            }

            .eventTravelDataMap {
            border: 2px solid #333;
            border-radius: 10px;
            height: 45vh;
            overflow: hidden;
            }

            .text {
            white-space: break-spaces;
            }

            .page-break {
            page-break-before: always;
            }

            table th,
            table td {
            text-align: left;
            }

            .categoryHeader {
            opacity: 0.3;
            border: 1px solid black;
            }

            body > div > div > div > span:nth-of-type(1) {
            display: none;
            }
            g > path:nth-of-type(1) {
            fill: rgb(3, 252, 223);
            }
            g > path:nth-of-type(2) {
            fill: rgb(0, 148, 130);
            }
            g > path:nth-of-type(3) {
            fill: rgb(115, 82, 0);
            }
            g > path:nth-of-type(4) {
            fill: rgb(203, 145, 0);
            }
            g > path:nth-of-type(5) {
            fill: rgb(255, 183, 0);
            }

            svg > text {
            font-size: 2.5rem;
            transform: translate(-55px, 15px);
            }
            h2 ~ div:first-of-type {
            flex-direction: row;
            display: flex;
            padding: 12px;
            }
            .vdPie svg {
            display: flex;
            justify-content: center;
            align-items: center;
            }
            b {
            padding: 0 !important;
            }
            .sustainabilityReport > tbody > tr:nth-of-type(4) {
            display: none;
            }
            .MuiChartsLegend-root {
            font-weight: bold;
            font-size: 20px;
            transform: translate(340px, 30px);
            font-family: Arial;
            }

            .MuiChartsLegend-series.css-1j2l0jy-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-label.css-162kp96-MuiChartsLegend-label {
            transform: translateX(-0px) translateY(-20px);
            }
            .MuiChartsLegend-series.css-1j2l0jy-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-mark {
            fill: rgb(3, 252, 223);
            width: 20px;
            height: 20px;
            transform: translateX(-30px) translateY(-35px);
            }
            .MuiChartsLegend-series.css-brh586-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-label.css-162kp96-MuiChartsLegend-label {
            transform: translateX(0) translateY(10px);
            }
            .MuiChartsLegend-series.css-brh586-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-mark {
            fill: rgb(0, 148, 130);
            width: 20px;
            height: 20px;
            transform: translateX(-30px) translateY(-6px);
            }
            .MuiChartsLegend-series.css-jc81iv-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-label.css-162kp96-MuiChartsLegend-label {
            transform: translateX(0) translateY(40px);
            }
            .MuiChartsLegend-series.css-jc81iv-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-mark {
            fill: rgb(115, 82, 0);
            width: 20px;
            height: 20px;
            transform: translateX(-30px) translateY(25px);
            }
            .MuiChartsLegend-series.css-dxgyzf-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-label.css-162kp96-MuiChartsLegend-label {
            transform: translateX(0) translateY(70px);
            }
            .MuiChartsLegend-series.css-dxgyzf-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-mark {
            fill: rgb(203, 145, 0);
            transform: translateX(-30px) translateY(54px);
            width: 20px;
            height: 20px;
            }
            .MuiChartsLegend-series.css-1pay8xb-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-label.css-162kp96-MuiChartsLegend-label {
            transform: translateX(0) translateY(100px);
            }
            .MuiChartsLegend-series.css-1pay8xb-MuiChartsLegend-chartsSeriesLegendGroup
            .MuiChartsLegend-mark {
            fill: rgb(255, 183, 0);
            transform: translateX(-30px) translateY(83px);
            width: 20px;
            height: 20px;
            }
        </style>
            <head>
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Anton&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Secular+One&family=Shrikhand&display=swap" rel="stylesheet">
      </head>
      <body>
        <div style="font-family: 'Roboto Condensed', sans-serif;">
        ${pdfDoc}
        </div>
        
      </div>
      </body>
            `,
    };

    try {
      // Using Axios to send a POST request
      const response = await axios.post("https://europe-west3-venuehub-64e72.cloudfunctions.net/generatePdf", htmlData, {
        responseType: "blob", // Important: Set the response type to 'blob' for PDF download
      });
      console.log(response);

      // Creating a Blob for the PDF and generating a download link
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);
      console.log("blob", blob);
      //   const link = document.createElement("a");
      link.href = downloadUrl;
      // link.setAttribute("download", agreementId + ".pdf"); // Name the download file
      link.setAttribute("download", pdfName + ".pdf"); // Name the download file
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // const storageRef = ref(storage, `agreements/${agreementId}.pdf`);
      const storageRef = ref(storage, `agreements/${pdfName}.pdf`);
      // Upload the PDF to Firebase Storag
      if (blob.size > 10000) storePDF();
      async function storePDF() {
        const snapshot = await uploadBytes(storageRef, blob);
        const pdfUrl = await getDownloadURL(snapshot.ref);
        setPdfUrl(pdfUrl);
        await updateDoc(doc(db, "Agreements", pdfName), {
          agreementPdf: pdfUrl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching the PDF:", error);
    }
  }

  function downloadPDF() {
    console.log("clicked");
    link.click();
  }

  return (
    <>
      <div>
        {/* <Button onClick={downloadPDF} size="small"> */}
        <Button onClick={() => handlePDF()} size="small">
          {loading && <CircularProgress style={{ zoom: "0.4", marginRight: "20px" }} />}
          {!loading ? (
            <>
              <Download style={{ marginRight: "10px" }} /> Download PDF
            </>
          ) : (
            "Creating PDF..."
          )}
        </Button>
      </div>

      {/* <div>
        {includedAttachments.length > 0 && (
          <div>
            {includedAttachments.map((attachment) => (
              <div>
                {attachment.url.includes(".pdf") ? (
                  <>
                    🩻{" "}
                    <a href={attachment.url} style={{ color: "black" }}>
                      {attachment.name}
                    </a>
                  </>
                ) : attachment.url.includes(".doc") || attachment.url.includes(".docx") ? (
                  <>
                    🩻{" "}
                    <a href={attachment.url} style={{ color: "black" }}>
                      {attachment.name}
                    </a>
                  </>
                ) : attachment.url.includes(".xls") || attachment.url.includes(".xlsx") ? (
                  <>
                    🩻{" "}
                    <a href={attachment.url} style={{ color: "black" }}>
                      {attachment.name}
                    </a>
                  </>
                ) : null}
              </div>
            ))}
          </div>
        )}
      </div> */}
    </>
  );
}

export default PdfHandlerGeneral;

// <style>

// .reportData .sustainabilityReport {
//   transform: scale(1);
//   border: 1px solid #444;
//   padding: 5px;
//   color: #666;
//   width: 100%;
//   font-size: 17px;
//   }

//   .sustainabilityReport th {
//   display: none;
//   padding: 10px 10px !important;
//   }

// .sustainabilityReport {
// transform: scale(1);
// border: 1px solid #444;
// padding: 5px;
// color: #666;
// width: 100%;
// font-size: 12px;
// }

// .sustainabilityReport table {
// opacity: 0.9;
// width: 100%;
// }

// .sustainabilityReport table td {
// color: #00ffd499 !important;
// }

// .sustainabilityReport tr {
// border-bottom: 1px solid #444;
// }

// .sustainabilityReport th {
// color: #666;
// padding: 0px 5px;
// background: "#222" !important;
// border-bottom: 1px solid #444;
// }

// .sustainabilityReport td {
//   padding: 0px 5px;
//   background: "red" !important;
// }

// .sustainabilityReport .travelImpact {
//   text-align: center;
// }

// .vdPie{zoom:0.5;}

// .travelReport {
//   text-align: center;
//   position: absolute;
//   bottom: 1px;
//   width: 100%;
//   /* pointer-events: none; */
//   background: #000000bb;
//   backdrop-filter: blur(5px);
// }

// .travelReport td {
//   color: #00ffd499;
// }

// .eventTravelDataMap {
//   border: 2px solid #333;
//   border-radius: 10px;
//   height: 45vh;
//   overflow: hidden;
// }

// .text {
//   white-space: break-spaces;
// }

// .page-break {
//   page-break-before: always;
// }

// table th, table td {
//   text-align:left;
// }

// .categoryHeader{
// opacity:0.3;
// border: 1px solid black;
// }

// </style>
