/** @format */

import { Divider, FormControlLabel, Grid, IconButton, Switch, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeNumber, numberFormatted, removePercentage } from "../../helpers/Numbers";
import { calculateBookingPrice } from "../MiscUIComponents/calculateBookingPrice";
import RefreshIcon from "@mui/icons-material/Refresh";
import { current } from "@reduxjs/toolkit";
import VatSelector from "../MiscUIComponents/VatSelector";
import QuickSpecs from "../Documents/QuickSpecs";
import { updateProductsWithNewModuleData } from "../MiscUIComponents/updateProductsWithNewModuleData";
import { updateModulesWithNewModuleData } from "../MiscUIComponents/updateModulesWithNewModules";
import ConfirmDialog from "../../helpers/ConfirmDialog";

const BookingFeatureEditor = ({ bookingData, setBookingData }) => {
  const superOwner = useSelector((state) => state.counter.superOwner);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  // const product = bookingData.venue.products.find((product) => product.productid === bookingData.productid);
  // console.log("product", product);
  console.log("BookingFeatures", bookingData.bookingFeatures);
  console.log("💄 bookingData", bookingData);
  // const [features, setFeatures] = useState(bookingData.bookingFeatures ? bookingData.bookingFeatures : product ? product.features : []);
  const [features, setFeatures] = useState(bookingData.bookingFeatures ? bookingData.bookingFeatures : currentVenue.features);
  const [sortedFeatures, setSortedFeatures] = useState();
  // const [features, setFeatures] = useState(currentVenue.features);
  // const [bookingFeatures, setBookingFeatures] = useState(bookingData.bookingFeatures);
  // const [venueFeatures, setVenueFeatures] = useState(currentVenue.features);

  const handleSelect = async (checked, additionalId, optionId, type) => {
    console.log("checked", checked, "additionalId", additionalId, "optionId", optionId, "type", type);
    let tmp = JSON.parse(JSON.stringify(features));

    //Check if unique
    if (type === "unique") {
      tmp = await setSelectedToFalseForAllOptions(additionalId);
    }

    // if this feature doesn't have a value for quantity or each but do have a price or if quantity or each is set to 0 even though there is a price, set quantity to 1 and each to price.
    if (
      tmp[additionalId].options[optionId].price > 0 &&
      !tmp[additionalId].options[optionId].quantity &&
      !tmp[additionalId].options[optionId].each
    ) {
      tmp[additionalId].options[optionId].quantity = 1;
      tmp[additionalId].options[optionId].each = tmp[additionalId].options[optionId].price;
    } else if (tmp[additionalId].options[optionId].price > 0 && tmp[additionalId].options[optionId].quantity < 1) {
      tmp[additionalId].options[optionId].quantity = 1;
      tmp[additionalId].options[optionId].each = tmp[additionalId].options[optionId].price;
    }

    // if (
    //   tmp[additionalId].options[optionId].price &&
    //   !tmp[additionalId].options[optionId].quantity &&
    //   !tmp[additionalId].options[optionId].each
    // ) {
    //   tmp[additionalId].options[optionId].quantity = 1;
    //   tmp[additionalId].options[optionId].each = tmp[additionalId].options[optionId].price;
    // }

    tmp[additionalId].options[optionId].selected = checked;

    setFeatures(tmp);
    setBookingData((prev) => ({ ...prev, bookingFeatures: tmp }));
  };

  const handleChangeValue = (additionalId, optionId, key, value) => {
    // const isValid = value === "" || value === "-" || /^-?\d+$/.test(value);
    const isValid = true;
    const tmp = JSON.parse(JSON.stringify(features));
    tmp[additionalId].options[optionId][key] = value;
    // tmp[additionalId].options[optionId][key] = value === "" ? " " : Number(value); // Set the new value, but if empty provide a blank (otherwise the field disapears).
    if (key === "quantity" || key === "each") {
      // if (tmp[additionalId].options[optionId].quantity < 1) tmp[additionalId].options[optionId].quantity = 1; // Make sure quantity is at least 1
      // calculate price
      if (isValid) {
        // console.log("price", tmp[additionalId].options[optionId]["price"]);
        if (parseFloat(value) < 0) {
          const tmpPrice =
            makeNumber(-tmp[additionalId].options[optionId]["each"]) * makeNumber(tmp[additionalId].options[optionId]["quantity"] || 1);
          tmp[additionalId].options[optionId]["price"] = -tmpPrice;

          // tmp[additionalId].options[optionId]["price"] =
          //   makeNumber(-tmp[additionalId].options[optionId]["each"]) * makeNumber(tmp[additionalId].options[optionId]["quantity"] || 1);
        } else {
          tmp[additionalId].options[optionId]["price"] =
            makeNumber(tmp[additionalId].options[optionId]["each"]) * makeNumber(tmp[additionalId].options[optionId]["quantity"]);
        }

        if (!isFinite(tmp[additionalId].options[optionId]["price"])) tmp[additionalId].options[optionId]["price"] = "..."; // make sure price is a number
      } else {
        return;
      }
    }
    setFeatures(tmp);
    setBookingData((prev) => ({ ...prev, bookingFeatures: tmp }));
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const setSelectedToFalseForAllOptions = (additionalId) => {
    // Set all selected===false (happens if an option with type==="unique" is set in order to only have one at the time)
    //first get all options for capacity
    let featureOptions = Object.values(features).find((obj) => obj.additionalId === additionalId);
    // then make a temporary "options"...
    let tmpOptions = JSON.parse(JSON.stringify(featureOptions.options));
    // ...to set all selected to false
    Object.values(tmpOptions).map((option) => (option.selected = false));
    // lastly make temporary features...
    let tmpFeatures = JSON.parse(JSON.stringify(features));
    // ...and set the options of this feature to new values (selected===false)
    tmpFeatures[additionalId].options = tmpOptions;
    // ...to return tmpFeatures to become tmp in handleSelect
    return tmpFeatures;
  };

  // When bookingData changes, update features
  useEffect(() => {
    setFeatures(bookingData.bookingFeatures);
  }, [bookingData]);

  const [editMode, setEditMode] = useState(false);
  // const [featuresToShow, setFeaturesToShow] = useState();

  // Don't show features that don't contain selected options except in "edit mode"

  // const featuresToShow = editMode
  //   ? features
  //   : Object.keys(features || {})
  //       .filter((featureKey) => {
  //         const feature = features[featureKey];
  //         return Object.keys(feature.options || {}).some((optionKey) => {
  //           const option = feature.options[optionKey];
  //           return option.selected === true;
  //         });
  //       })
  //       .map((key) => features[key]);
  const featuresToShow = editMode
    ? features
    : Object.entries(features || {})
        .filter(([featureKey, feature]) => Object.values(feature.options || {}).some((option) => option.selected === true))
        .map(([key, feature]) => feature);

  const venueFeatures = currentVenue && currentVenue.features;

  // Merge any new modules or options
  useEffect(() => {
    setTimeout(() => {
      mergeNewFeatures(features, venueFeatures);
      // mergeNewFeatures(bookingData.bookingFeatures, venueFeatures);
    }, 900);
  }, [editMode]);

  // Function to merge new features and options from venueFeatures into bookingFeatures
  function mergeNewFeatures(bookingFeatures, venueFeatures) {
    // if (bookingFeatures === undefined || bookingFeatures === "-") return;

    bookingFeatures = JSON.parse(JSON.stringify(bookingFeatures && bookingFeatures));
    // venueFeatures = JSON.parse(JSON.stringify(venueFeatures));
    venueFeatures = currentVenue.features && JSON.parse(JSON.stringify(currentVenue.features));
    console.log("bookingFeatures", bookingFeatures);
    console.log("venueFeatures", venueFeatures);

    // Iterate over venueFeatures
    if (!venueFeatures) return;
    Object.keys(venueFeatures).forEach((featureKey) => {
      if (!bookingFeatures[featureKey]) {
        // Initialize bookingFeatures as an object if it is not already one
        if (typeof bookingFeatures === "string") {
          bookingFeatures = {}; // Convert to an empty object
        }
        // If the feature doesn't exist in bookingFeatures, add it
        bookingFeatures[featureKey] = venueFeatures[featureKey];
      } else {
        // If the feature exists, check options within it
        if (venueFeatures[featureKey].options) {
          // Ensure the bookingFeatures feature has an options object
          if (!bookingFeatures[featureKey].options) {
            bookingFeatures[featureKey].options = {};
          }
          // Iterate over venueFeatures feature options
          Object.keys(venueFeatures[featureKey].options).forEach((optionKey) => {
            // Add the option if it doesn't exist in bookingFeatures
            if (!bookingFeatures[featureKey].options[optionKey]) {
              bookingFeatures[featureKey].options[optionKey] = venueFeatures[featureKey].options[optionKey];
            }
            // If the option exists, it remains unchanged
          });
        }
        // Any additional properties of features can be checked and merged similarly
      }
    });
    console.log("This should be it", bookingFeatures);
    setFeatures(bookingFeatures);
  }

  const isUpdated = (feature, option) => {
    if (
      currentVenue.features &&
      currentVenue.features[feature.additionalId] &&
      currentVenue.features[feature.additionalId].options &&
      currentVenue.features[feature.additionalId].options[option.optionId] &&
      currentVenue.features[feature.additionalId].options[option.optionId].option === option.option
    )
      return false;
    return true;
  };

  // Refresh option to global value (from currentVenue.features)
  function refreshOption(feature, optionId) {
    let tmp = { ...JSON.parse(JSON.stringify(features)) };
    let currentQuantity = tmp[feature.additionalId].options[optionId].quantity;
    // Get the global option value from module settings for currentVenue
    tmp[feature.additionalId].options[optionId] = { ...currentVenue.features[feature.additionalId].options[optionId] };
    // Set option to selected on update
    tmp[feature.additionalId].options[optionId].selected = true;
    // tmp[feature.additionalId].options[optionId].quantity = currentQuantity;

    setFeatures(tmp);
    setBookingData((prev) => ({ ...prev, bookingFeatures: tmp }));
  }

  // Refresh additional to global value (from currentVenue.features)
  function refreshAdditional(feature) {
    if (!currentVenue.features[feature.additionalId]) {
      alert(features[feature.additionalId].additional + " doesn't exist in global modules");
      return;
    }
    let tmp = JSON.parse(JSON.stringify(features));
    console.log("🫁 FEATURE", feature);
    console.log(currentVenue.features);
    console.log("global value", currentVenue.features[feature.additionalId].additional);
    tmp[feature.additionalId].additional = currentVenue.features[feature.additionalId].additional;

    setFeatures(tmp);
    setBookingData((prev) => ({ ...prev, bookingFeatures: tmp }));
  }

  function refreshAll(bookingFeatures, venueFeatures) {
    // alert("refreshing all");
    const updatedToGlobalFeatures = updateModulesWithNewModuleData(bookingFeatures, venueFeatures);
    console.log("updatedToGlobalFeatures", updatedToGlobalFeatures);
    console.log("bookingFeatures", bookingFeatures);
    // setFeatures(updatedToGlobalFeatures);
    setBookingData((prev) => ({ ...prev, bookingFeatures: updatedToGlobalFeatures }));
  }

  function hasChanged(feature, option) {
    if (feature.options[option.optionId].option !== option.option) return true;
    // if (feature.options[option.optionId].quantity !== option.quantity) return true;
    // if (feature.options[option.optionId].vat !== option.vat) return true;
    return false;
  }

  useEffect(() => {
    if (!bookingData.bookingFeatures) return;
    let tmp = JSON.parse(JSON.stringify(bookingData.bookingFeatures));

    Object.values(tmp).map((feature) => {
      console.log("🫁 FEATURE", feature.additional);

      if (Object.values(feature.options).length === 0) return;
      Object.values(feature.options).map((option) => {
        // if this feature doesn't have a value for quantity or each but do have a price or if quantity or each is set to 0 even though there is a price, set quantity to 1 and each to price.
        if ((option.price > 0 && !option.quantity) || !option.each) {
          tmp[feature.additionalId].options[option.optionId].quantity = 1;
          tmp[feature.additionalId].options[option.optionId].each = option.price;
        } else if (option.price > 0 && option.quantity < 1) {
          tmp[feature.additionalId].options[option.optionId].quantity = 1;
          tmp[feature.additionalId].options[option.optionId].each = option.price;
        }

        if (option.price === 0 && option.quantity > 0) {
          tmp[feature.additionalId].options[option.optionId].price = option.each * option.quantity;
        }
      });
    });
    setFeatures(tmp);
  }, [bookingData.bookingFeatures]);

  return (
    <div style={{ height: "50vh", overflow: "scroll" }} className="bookingFeatureEditor">
      <div className="bookingFeatureEditorTop">
        <h6>
          <span>Specifications</span>
          <span style={{ marginLeft: "15px", float: "right" }}>
            <QuickSpecs bookingData={bookingData} setBookingData={setBookingData} />
          </span>
          <span style={{ marginLeft: "20px", fontSize: "10px", float: "right" }}>
            <FormControlLabel
              control={<Switch size="small" checked={editMode} onChange={() => setEditMode(!editMode)} />}
              label="Edit Mode"
            />
          </span>
          <span>
            <Tooltip title="Refresh all options to global values">
              <ConfirmDialog
                onConfirm={() => refreshAll(features, venueFeatures)}
                variant="outlined"
                noButton
                header="Refresh all modules and options?"
                message="Fetching updated modules and options also resets values."
              >
                <IconButton
                  className="refreshAll refreshButton"
                  size="small"
                  style={{ float: "right" }}
                  // onClick={() => refreshAll(features, venueFeatures)}
                >
                  <RefreshIcon />
                </IconButton>
              </ConfirmDialog>
            </Tooltip>
          </span>
        </h6>
      </div>

      <Grid container xs={12}>
        {features && (
          <>
            <table className="smallTable">
              <thead>
                {editMode && <th>Activate</th>}
                <th>Option</th>
                <th>Each</th>
                <th>Quantity</th>
                <th>Vat</th>
                <th>Sum</th>
              </thead>

              <tbody>
                {Object.values(featuresToShow !== undefined && featuresToShow)
                  .sort((a, b) => a.additional.localeCompare(b.additional, "sv")) // Sort by feature.additional
                  .map((feature) => (
                    <>
                      {/* show FREE TEXT first */}
                      {feature.additional === "Free Text" && (
                        <React.Fragment className="feature">
                          <tr className="tableH7" style={{ borderTop: "10px solid transparent" }}>
                            <td>
                              <h7>{feature.additional}</h7>
                              {editMode && (
                                <IconButton
                                  className="refreshButton"
                                  size="small"
                                  style={{ float: "right" }}
                                  onClick={() => refreshAdditional(feature)}
                                >
                                  <RefreshIcon />
                                </IconButton>
                              )}
                            </td>
                            <td colspan="5"></td>
                          </tr>

                          {typeof feature === "object" &&
                            feature !== null &&
                            Object.values(feature.options)
                              // .sort((a, b) => a.option.localeCompare(b.option, "sv")) //sort by ooption (name)

                              // .sort((a, b) => b.price - a.price) // instead sort by price
                              .map((option) => (
                                <>
                                  {(option.selected ||
                                    (currentVenue.features &&
                                      currentVenue.features[feature.additionalId] &&
                                      currentVenue.features[feature.additionalId].options &&
                                      currentVenue.features[feature.additionalId].options[option.optionId] &&
                                      currentVenue.features[feature.additionalId].options[option.optionId].option)) && (
                                    <tr
                                      style={{
                                        opacity: option.selected ? "1" : "0.3",
                                        width: "100%",
                                        // background: "#00ffd433",
                                      }}
                                      className={!option.selected && !editMode ? "editOff" : "editOn"}
                                    >
                                      {console.log("OPTION", option)}
                                      {editMode && (
                                        <td>
                                          {editMode &&
                                            currentVenue.features &&
                                            currentVenue.features[feature.additionalId] &&
                                            currentVenue.features[feature.additionalId].options &&
                                            currentVenue.features[feature.additionalId].options[option.optionId] &&
                                            currentVenue.features[feature.additionalId].options[option.optionId].option && (
                                              <Tooltip
                                                title={`Refresh option to: ${
                                                  currentVenue.features &&
                                                  currentVenue.features[feature.additionalId] &&
                                                  currentVenue.features[feature.additionalId].options &&
                                                  currentVenue.features[feature.additionalId].options[option.optionId] &&
                                                  currentVenue.features[feature.additionalId].options[option.optionId].option
                                                    ? currentVenue.features[feature.additionalId].options[option.optionId].option
                                                    : "⚠️ This module has been removed"
                                                }`}
                                                placement="left"
                                              >
                                                <IconButton
                                                  className="refreshButton"
                                                  size="small"
                                                  style={{ float: "right" }}
                                                  onClick={() =>
                                                    currentVenue.features &&
                                                    currentVenue.features[feature.additionalId] &&
                                                    currentVenue.features[feature.additionalId].options &&
                                                    currentVenue.features[feature.additionalId].options[option.optionId] &&
                                                    currentVenue.features[feature.additionalId].options[option.optionId].option &&
                                                    refreshOption(feature, option.optionId)
                                                  }
                                                >
                                                  <RefreshIcon />
                                                </IconButton>
                                              </Tooltip>
                                            )}
                                          {console.log("option selected:", option.selected)}
                                          <Switch
                                            size="small"
                                            checked={option.selected ? true : false}
                                            onClick={(e) =>
                                              handleSelect(
                                                !option.selected,
                                                feature.additionalId,
                                                option.optionId,
                                                feature.unique ? "unique" : null
                                              )
                                            }
                                          />
                                        </td>
                                      )}
                                      <td>
                                        {/* <Tooltip title={`${option.info && option.info} ${option.specs && ` ${option.specs}`}`}> */}
                                        <Tooltip title={option.info}>
                                          {option.option} {console.log("feature", feature)}
                                          {console.log("option", feature.additional, option.optionId, option)}
                                        </Tooltip>
                                      </td>
                                      {
                                        <>
                                          <td>
                                            {/* {option.each && ( */}
                                            <Tooltip title="Price each">
                                              <input
                                                disabled={!option.selected}
                                                type="number"
                                                value={option.each || option.price}
                                                onChange={(e) => {
                                                  handleChangeValue(feature.additionalId, option.optionId, "each", e.target.value);
                                                }}
                                                onFocus={handleFocus}
                                              />
                                            </Tooltip>
                                            {/* )} */}
                                          </td>
                                          <td>
                                            {/* {option.quantity && ( */}
                                            <Tooltip title="Quantity">
                                              <input
                                                disabled={!option.selected}
                                                type="number"
                                                value={option.quantity || 1}
                                                onChange={(e) => {
                                                  handleChangeValue(feature.additionalId, option.optionId, "quantity", e.target.value);
                                                }}
                                                onFocus={handleFocus}
                                              />
                                            </Tooltip>
                                            {/* )} */}
                                          </td>
                                          <td style={{ scale: "0.8" }}>
                                            <VatSelector
                                              handleChangeOption={handleChangeValue}
                                              value={option.vat ? option.vat : "25%"}
                                              item={feature}
                                              optionId={option.optionId}
                                            />
                                          </td>
                                          <td>
                                            {/* {option.price && ( */}
                                            <Tooltip title="Sum">
                                              <input
                                                disabled={!option.selected}
                                                type="number"
                                                value={option.price}
                                                onChange={(e) => {
                                                  handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value);
                                                }}
                                                onFocus={handleFocus}
                                                style={{ color: !superOwner && removePercentage(option.vat) > 0 ? "inherit" : "red" }} // Show green if there is a positive vat
                                              />
                                            </Tooltip>
                                            {/* )} */}
                                          </td>
                                          {/* <td>{option.vat}</td> */}
                                          {/* handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value) */}
                                        </>
                                      }
                                    </tr>
                                  )}
                                </>
                              ))}
                        </React.Fragment>
                      )}
                    </>
                  ))}
                {Object.values(featuresToShow !== undefined && featuresToShow)
                  .sort((a, b) => a.additional.localeCompare(b.additional, "sv")) // Sort by feature.additional
                  .map((feature) => (
                    <>
                      {/* show RENTAL FEE second */}
                      {(feature.additional === "Rental Fee" || feature.additional === "Lokalhyra") &&
                        Object.values(feature.options).length > 0 && (
                          <React.Fragment className="feature">
                            <tr className="tableH7" style={{ borderTop: "10px solid transparent" }}>
                              <td>
                                <h7>{feature.additional}</h7>
                                {editMode && (
                                  <IconButton
                                    className="refreshButton"
                                    size="small"
                                    style={{ float: "right" }}
                                    onClick={() => refreshAdditional(feature)}
                                  >
                                    <RefreshIcon />
                                  </IconButton>
                                )}
                              </td>
                              <td colspan="5"></td>
                            </tr>

                            {typeof feature === "object" &&
                              feature !== null &&
                              Object.values(feature.options)
                                // .sort((a, b) => a.option.localeCompare(b.option, "sv")) //sort by ooption (name)

                                // .sort((a, b) => b.price - a.price) // instead sort by price
                                .map((option) => (
                                  <>
                                    {(option.selected ||
                                      (currentVenue.features &&
                                        currentVenue.features[feature.additionalId] &&
                                        currentVenue.features[feature.additionalId].options &&
                                        currentVenue.features[feature.additionalId].options[option.optionId] &&
                                        currentVenue.features[feature.additionalId].options[option.optionId].option)) && (
                                      <tr
                                        style={{
                                          opacity: option.selected ? "1" : "0.3",
                                          width: "100%",
                                          // background: "#00ffd433",
                                        }}
                                        className={!option.selected && !editMode ? "editOff" : "editOn"}
                                      >
                                        {console.log("OPTION", option)}
                                        {editMode && (
                                          <td>
                                            {editMode &&
                                              currentVenue.features &&
                                              currentVenue.features[feature.additionalId] &&
                                              currentVenue.features[feature.additionalId].options &&
                                              currentVenue.features[feature.additionalId].options[option.optionId] &&
                                              currentVenue.features[feature.additionalId].options[option.optionId].option && (
                                                <Tooltip
                                                  title={`Refresh option to: ${
                                                    currentVenue.features &&
                                                    currentVenue.features[feature.additionalId] &&
                                                    currentVenue.features[feature.additionalId].options &&
                                                    currentVenue.features[feature.additionalId].options[option.optionId] &&
                                                    currentVenue.features[feature.additionalId].options[option.optionId].option
                                                      ? currentVenue.features[feature.additionalId].options[option.optionId].option
                                                      : "⚠️ This module has been removed"
                                                  }`}
                                                  placement="left"
                                                >
                                                  <IconButton
                                                    className="refreshButton"
                                                    size="small"
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                      currentVenue.features &&
                                                      currentVenue.features[feature.additionalId] &&
                                                      currentVenue.features[feature.additionalId].options &&
                                                      currentVenue.features[feature.additionalId].options[option.optionId] &&
                                                      currentVenue.features[feature.additionalId].options[option.optionId].option &&
                                                      refreshOption(feature, option.optionId)
                                                    }
                                                  >
                                                    {hasChanged(feature, option) && <RefreshIcon />}
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                            {console.log("option selected:", option.selected)}
                                            <Switch
                                              size="small"
                                              checked={option.selected ? true : false}
                                              onClick={(e) =>
                                                handleSelect(
                                                  !option.selected,
                                                  feature.additionalId,
                                                  option.optionId,
                                                  feature.unique ? "unique" : null
                                                )
                                              }
                                            />
                                          </td>
                                        )}
                                        <td>
                                          {/* <Tooltip title={`${option.info && option.info} ${option.specs && ` ${option.specs}`}`}> */}
                                          <Tooltip title={option.info}>
                                            {option.option} {console.log("feature", feature)}
                                            {console.log("option", feature.additional, option.optionId, option)}
                                          </Tooltip>
                                        </td>
                                        {
                                          <>
                                            <td>
                                              {/* {option.each && ( */}
                                              <Tooltip title="Price each">
                                                <input
                                                  disabled={!option.selected}
                                                  type="text"
                                                  value={option.each || option.price}
                                                  onChange={(e) => {
                                                    handleChangeValue(feature.additionalId, option.optionId, "each", e.target.value);
                                                  }}
                                                  onFocus={handleFocus}
                                                />
                                              </Tooltip>
                                              {/* )} */}
                                            </td>
                                            <td>
                                              {/* {option.quantity && ( */}
                                              <Tooltip title="Quantity">
                                                <input
                                                  disabled={!option.selected}
                                                  type="number"
                                                  value={option.quantity || 1}
                                                  onChange={(e) => {
                                                    handleChangeValue(feature.additionalId, option.optionId, "quantity", e.target.value);
                                                  }}
                                                  onFocus={handleFocus}
                                                />
                                              </Tooltip>
                                              {/* )} */}
                                            </td>
                                            <td style={{ scale: "0.8" }}>
                                              <VatSelector
                                                handleChangeOption={handleChangeValue}
                                                value={option.vat ? option.vat : "25%"}
                                                item={feature}
                                                optionId={option.optionId}
                                              />
                                            </td>
                                            <td>
                                              {/* {option.price && ( */}
                                              <Tooltip title="Sum">
                                                <input
                                                  disabled={!option.selected}
                                                  type="text"
                                                  value={option.price}
                                                  onChange={(e) => {
                                                    handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value);
                                                  }}
                                                  onFocus={handleFocus}
                                                  style={{ color: !superOwner && removePercentage(option.vat) > 0 ? "inherit" : "red" }} // Show green if there is a positive vat
                                                />
                                              </Tooltip>
                                              {/* )} */}
                                            </td>
                                            {/* <td>{option.vat}</td> */}
                                            {/* handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value) */}
                                          </>
                                        }
                                      </tr>
                                    )}
                                  </>
                                ))}
                          </React.Fragment>
                        )}
                    </>
                  ))}
                {Object.values(featuresToShow !== undefined && featuresToShow)
                  .sort((a, b) => a.additional.localeCompare(b.additional, "sv")) // Sort by feature.additional
                  .map((feature) => (
                    <>
                      {/* then show THE REST except free text and rental fee (+capacity and staff) */}
                      {feature.additional !== "Staff" &&
                        feature.additional !== "Rental Fee" &&
                        feature.additional !== "Lokalhyra" &&
                        feature.additional !== "Free Text" &&
                        feature.additional !== "Capacity" && (
                          <React.Fragment className="feature">
                            {feature.additional && (
                              <tr className="tableH7" style={{ borderTop: "10px solid transparent" }}>
                                <td>
                                  <h7>{feature.additional}</h7>
                                  {editMode && (
                                    <IconButton
                                      className="refreshButton"
                                      size="small"
                                      style={{ float: "right" }}
                                      onClick={() => refreshAdditional(feature)}
                                    >
                                      <RefreshIcon />
                                    </IconButton>
                                  )}
                                </td>
                                <td colspan="5"></td>
                              </tr>
                            )}

                            {typeof feature === "object" &&
                              feature !== null &&
                              Object.values(feature.options)
                                // .sort((a, b) => a.option.localeCompare(b.option, "sv")) //sort by ooption (name)

                                // .sort((a, b) => b.price - a.price) // instead sort by price
                                .map((option) => (
                                  <>
                                    {(option.selected ||
                                      (currentVenue.features &&
                                        currentVenue.features[feature.additionalId] &&
                                        currentVenue.features[feature.additionalId].options &&
                                        currentVenue.features[feature.additionalId].options[option.optionId] &&
                                        currentVenue.features[feature.additionalId].options[option.optionId].option)) && (
                                      <tr
                                        style={{
                                          opacity: option.selected ? "1" : "0.3",
                                          width: "100%",
                                          // background: "#00ffd433",
                                        }}
                                        className={!option.selected && !editMode ? "editOff" : "editOn"}
                                      >
                                        {/* {console.log("OPTION", option)} */}
                                        {editMode && (
                                          <td>
                                            {editMode &&
                                              currentVenue.features &&
                                              currentVenue.features[feature.additionalId] &&
                                              currentVenue.features[feature.additionalId].options &&
                                              currentVenue.features[feature.additionalId].options[option.optionId] &&
                                              currentVenue.features[feature.additionalId].options[option.optionId].option && (
                                                <Tooltip
                                                  title={`Refresh option to: ${
                                                    currentVenue.features &&
                                                    currentVenue.features[feature.additionalId] &&
                                                    currentVenue.features[feature.additionalId].options &&
                                                    currentVenue.features[feature.additionalId].options[option.optionId] &&
                                                    currentVenue.features[feature.additionalId].options[option.optionId].option
                                                      ? currentVenue.features[feature.additionalId].options[option.optionId].option
                                                      : "⚠️ This module has been removed"
                                                  }`}
                                                  placement="left"
                                                >
                                                  <IconButton
                                                    className="refreshButton"
                                                    size="small"
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                      currentVenue.features &&
                                                      currentVenue.features[feature.additionalId] &&
                                                      currentVenue.features[feature.additionalId].options &&
                                                      currentVenue.features[feature.additionalId].options[option.optionId] &&
                                                      currentVenue.features[feature.additionalId].options[option.optionId].option &&
                                                      refreshOption(feature, option.optionId)
                                                    }
                                                  >
                                                    <RefreshIcon />
                                                  </IconButton>
                                                </Tooltip>
                                              )}
                                            {/* {console.log("option selected:", option.selected)} */}
                                            <Switch
                                              size="small"
                                              checked={option.selected ? true : false}
                                              onClick={(e) =>
                                                handleSelect(
                                                  !option.selected,
                                                  feature.additionalId,
                                                  option.optionId,
                                                  feature.unique ? "unique" : null
                                                )
                                              }
                                            />
                                          </td>
                                        )}
                                        <td>
                                          {/* <Tooltip title={`${option.info && option.info} ${option.specs && ` ${option.specs}`}`}> */}
                                          <Tooltip title={option.info}>
                                            {option.option}
                                            {/* {console.log("feature", feature)} */}
                                            {/* {console.log("option", feature.additional, option.optionId, option)} */}
                                          </Tooltip>
                                        </td>
                                        {
                                          <>
                                            <td>
                                              {/* {option.each && ( */}
                                              <Tooltip title="Price each">
                                                <input
                                                  disabled={!option.selected}
                                                  type="number"
                                                  value={option.each || option.price}
                                                  onChange={(e) => {
                                                    handleChangeValue(feature.additionalId, option.optionId, "each", e.target.value);
                                                  }}
                                                  onFocus={handleFocus}
                                                />
                                              </Tooltip>
                                              {/* )} */}
                                            </td>
                                            <td>
                                              {/* {option.quantity && ( */}
                                              <Tooltip title="Quantity">
                                                <input
                                                  disabled={!option.selected}
                                                  type="number"
                                                  value={option.quantity || 1}
                                                  onChange={(e) => {
                                                    handleChangeValue(feature.additionalId, option.optionId, "quantity", e.target.value);
                                                  }}
                                                  onFocus={handleFocus}
                                                />
                                              </Tooltip>
                                              {/* )} */}
                                            </td>
                                            <td style={{ scale: "0.8" }}>
                                              <VatSelector
                                                handleChangeOption={handleChangeValue}
                                                value={option.vat ? option.vat : "25%"}
                                                item={feature}
                                                optionId={option.optionId}
                                              />
                                            </td>
                                            <td>
                                              {/* {option.price && ( */}
                                              <Tooltip title="Sum">
                                                <input
                                                  disabled={!option.selected}
                                                  type="number"
                                                  value={option.price}
                                                  onChange={(e) => {
                                                    handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value);
                                                  }}
                                                  onFocus={handleFocus}
                                                  style={{ color: !superOwner && removePercentage(option.vat) > 0 ? "inherit" : "red" }} // Show green if there is a positive vat
                                                />
                                              </Tooltip>
                                              {/* )} */}
                                            </td>
                                            {/* <td>{option.vat}</td> */}
                                            {/* handleChangeValue(feature.additionalId, option.optionId, "price", e.target.value) */}
                                          </>
                                        }
                                      </tr>
                                    )}
                                  </>
                                ))}
                          </React.Fragment>
                        )}
                    </>
                  ))}
              </tbody>
            </table>
            <div className="bookingFeatureTotal">
              <table style={{ width: "100%", tableLayout: "fixed", width: "100%" }} className="smallTable">
                <tr style={{ borderTop: "1px solid #555", fontSize: "16px", width: "100%" }}>
                  <td style={{ textAlign: "left", textIndent: "10px" }} colspan={editMode ? "4" : "4"}>
                    <b>Total</b> {currentVenue.currency}
                  </td>
                  {editMode && <td></td>}
                  <Tooltip title={`${numberFormatted(calculateBookingPrice(bookingData.bookingFeatures, "totalIncVat"))} inc VAT`}>
                    <td>
                      <b>{numberFormatted(calculateBookingPrice(bookingData.bookingFeatures))}</b>
                    </td>
                  </Tooltip>
                </tr>
              </table>
            </div>
          </>
        )}
      </Grid>
    </div>
  );
};

export default BookingFeatureEditor;
