/** @format */

import { Avatar, Box, Button, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db, getBookingsByVenueid } from "../firebase";

import { CircularProgress } from "@mui/material";
// import { listenToOwnedVenuesBookings, reloadCalendarBookings } from "./Listeners";
import DisplayObject from "./displayObject";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoadingDots from "../components/UI helpers/LoadingDots";
import AddVenue from "../components/Forms/AddVenue";
import EditIcon from "@mui/icons-material/Edit";
import shortText from "./shortText";
import {
  storeAccessibleVenues,
  storeCurrentBookings,
  storeCurrentLoadingBookingsProgress,
  storeCurrentSettings,
  storeCurrentTicketSummary,
  storeCurrentVenue,
  storeCurrentVenueBookings,
} from "../features/venueFilter/venueFilterSlice";
import ObjectSize from "./ObjectSize";
import { set } from "date-fns";
import { updateAccessibleVenuesWhenCurrentVenue } from "../components/MiscUIComponents/updateAccessibleVenuesWhenCurrentVenue";
import {
  deleteDatabase,
  ensureStores,
  fetchCachedData,
  openDatabase,
  storeDataInIndexedDB,
} from "../components/Sidebar/IndexedDBForCurrentVenueBookings";

const SelectVenue = () => {
  const cs = useSelector((state) => state.counter.currentSettings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentTicketSummary = useSelector((state) => state.counter.currentTicketSummary);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const venueAccess = useSelector((state) => state.counter.venueAccess);

  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [loading, setLoading] = useState(false);
  const [loadingBookings, setLoadingBookings] = useState(0);
  const [value, setValue] = useState(cs.svVenueid ? cs.svVenueid : null);
  const [accessibleVenues, setAccessibleVenues] = useState([{}]);
  const [currentVenueid, setcurrentVenueid] = useState();
  const [groupedData, setGroupedData] = useState({});
  const [fetching, setFetching] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const [cached, setCached] = useState([]);
  const [online, setOnline] = useState(false);
  const [bookingsListenerIsSet, setBookingsListenerIsSet] = useState(false);

  // openDatabase();

  // THIS HERE IS A PROBLEM (but it still works)
  // currentVenue && listenToOwnedVenuesBookings();
  // currentVenue && reloadCalendarBookings(currentVenue);

  async function handleChange(venueid) {
    setOnline(false);
    dispatch(storeCurrentVenueBookings([]));
    dispatch(storeCurrentVenue(null));

    if (venueid === undefined || !venueid) return;
    setValue(venueid);
    setBookingsListenerIsSet(false);
    const venue = accessibleVenues.find((venue) => venue.venueid === venueid);
    dispatch(storeCurrentVenue(venue));
    // listenToVenueBookings(venue);

    // dispatch(storeCurrentSettings({ ...cs, svVenueid: venueid }));
  }

  // useEffect(() => {
  //   if (currentVenue.venueid === "none") return;
  //   if (bookingsListenerIsSet) alert("bookingsListenerIsSet");
  //   // alert("currentVenue: " + currentVenue.venueid);
  //   // alert("now D");
  //   // listenToVenueBookings(currentVenue);
  //   console.time("bookings time B");
  //   !bookingsListenerIsSet && listenToVenueBookings();
  // }, [currentVenue.venueid]);

  useEffect(() => {
    if (value === "none") return;
    if (bookingsListenerIsSet) return;
    listenToVenueBookings(currentVenue);
  }, [value]);

  // function listenToVenueBookings(currentVenue) {
  function listenToVenueBookings() {
    setWaiting(true);
    if (currentVenue.venueid === "none" || currentVenue.venueid === undefined) return;
    console.time("setListener"); // Start timing the entire function
    setListener(); // Uncomment this
    console.time("bookings time 4 sure");
    setLoading(true);

    let tempCurrentVenue = [];

    async function setListener() {
      if (currentVenue.venueid === undefined) return;
      if (currentVenue && currentVenue.venueid) {
        // First get cached bookings
        const getChachedBookings = await fetchCachedData(currentVenue.venueid);
        const cachedBookings = getChachedBookings.map((booking) => {
          // Remove products and features from cached bookings
          return {
            ...booking, // Spread the existing booking properties
            venue: {
              ...booking.venue, // Spread the existing venue properties
              products: [], // Replace products with an empty array
              features: [], // Replace features with an empty array
            },
          };
        });
        if (cachedBookings.length > 0) {
          setCached(cachedBookings);
          tempCurrentVenue = { ...currentVenue, bookings: cachedBookings };
          dispatch(storeCurrentVenue(tempCurrentVenue));
          dispatch(storeCurrentVenueBookings(cachedBookings));
        }

        // const q = query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid)); // Fetch only specific fields);
        // const unsubscribe = await onSnapshot(q, async (querySnapshot) => {
        //   // alert("loading bookings");
        //   // if (bookingsListenerIsSet) return;
        //   let bookings = [];
        //   const totalDocs = querySnapshot.size;
        //   alert("totalDocs: " + totalDocs);
        //   let currentIndex = 0;

        //   for (const doc of querySnapshot.docs) {
        //     bookings.push(doc.data());
        //     currentIndex++;

        //     // Update progress based on the current index
        //     const progressValue = (currentIndex / totalDocs) * 100;
        //     setLoadingBookings(progressValue);

        //     // Add a small delay to allow rendering of progress bar
        //     await new Promise((resolve) => setTimeout(resolve, 0.01));
        //   }

        //   bookings.sort(function (a, b) {
        //     return new Date(a.date) - new Date(b.date);
        //   });

        //   const minimizedBookings = bookings.map((booking) => {
        //     // Remove products and features from cached bookings
        //     return {
        //       ...booking, // Spread the existing booking properties
        //       venue: {
        //         ...booking.venue, // Spread the existing venue properties
        //         products: [], // Replace products with an empty array
        //         features: [], // Replace features with an empty array
        //       },
        //     };
        //   });

        //   tempCurrentVenue = { ...currentVenue, bookings: minimizedBookings }; // Modified
        //   setBookingsListenerIsSet(true);
        //   await dispatchToStore(tempCurrentVenue);
        //   await storeDataInIndexedDB(minimizedBookings, currentVenue.venueid); // Modified
        // });
        //
        //
        //

        // THIS IS THE NEW LISTENER That only listens to changes.
        const q = query(
          collection(db, "Bookings"),
          where("venueid", "==", currentVenue.venueid)
          // Optionally fetch specific fields only
          // select("field1", "field2")
        );

        const unsubscribe = onSnapshot(q, async (querySnapshot) => {
          console.time("onSnapshot"); // Measure time to initiate the snapshot listener
          // Only process changes, additions, and deletions
          querySnapshot.docChanges().forEach(async (change) => {
            if (change.type === "added") {
              console.log("Document added: ", change.doc.data());
              // Handle added document
            } else if (change.type === "modified") {
              console.log("Document modified: ", change.doc.data());
              // Handle modified document
            } else if (change.type === "removed") {
              console.log("Document removed: ", change.doc.id);
              // Handle removed document
            }
          });

          // Optionally, maintain a local cache to avoid redundant reprocessing
          const updatedBookings = querySnapshot.docs.map((doc) => doc.data());
          updatedBookings.sort((a, b) => new Date(a.date) - new Date(b.date));

          const minimizedBookings = updatedBookings.map((booking) => ({
            ...booking,
            venue: {
              ...booking.venue,
              products: [],
              features: [],
            },
          }));

          tempCurrentVenue = { ...currentVenue, bookings: minimizedBookings };
          setBookingsListenerIsSet(true);
          setLoadingBookings(100);

          // Dispatch and store minimized bookings
          await dispatchToStore(tempCurrentVenue);
          await storeDataInIndexedDB(minimizedBookings, currentVenue.venueid);
          console.timeEnd("onSnapshot"); // Measure time to initiate the snapshot listener
        });
        //
        //
        //
        // const unsubscribe = await onSnapshot(q, async (querySnapshot) => {
        //   alert("loading bookings");
        //   // if (bookingsListenerIsSet) return;
        //   console.timeEnd("onSnapshot"); // Measure time to initiate the snapshot listener
        //   console.time("processBookings"); // Time the loop that processes bookings

        //   let bookings = [];
        //   const totalDocs = querySnapshot.size;
        //   alert("totalDocs: " + totalDocs);
        //   let currentIndex = 0;

        //   for (const doc of querySnapshot.docs) {
        //     console.time("processDoc"); // Time each document processing
        //     bookings.push(doc.data());
        //     currentIndex++;

        //     // Update progress based on the current index
        //     const progressValue = (currentIndex / totalDocs) * 100;
        //     setLoadingBookings(progressValue);

        //     // Add a small delay to allow rendering of progress bar
        //     await new Promise((resolve) => setTimeout(resolve, 0.01));
        //     console.timeEnd("processDoc"); // End timing for this document
        //   }

        //   console.timeEnd("processBookings"); // End timing for processing bookings

        //   console.time("sortBookings"); // Time the sorting operation
        //   bookings.sort(function (a, b) {
        //     return new Date(a.date) - new Date(b.date);
        //   });
        //   console.timeEnd("sortBookings"); // End timing for sorting

        //   const minimizedBookings = bookings.map((booking) => {
        //     // Remove products and features from cached bookings
        //     return {
        //       ...booking, // Spread the existing booking properties
        //       venue: {
        //         ...booking.venue, // Spread the existing venue properties
        //         products: [], // Replace products with an empty array
        //         features: [], // Replace features with an empty array
        //       },
        //     };
        //   });

        //   console.time("dispatchToStore"); // Time dispatching to the store
        //   // tempCurrentVenue = { ...currentVenue, bookings }; // Original
        //   tempCurrentVenue = { ...currentVenue, bookings: minimizedBookings }; // Modified
        //   setBookingsListenerIsSet(true);
        //   await dispatchToStore(tempCurrentVenue);
        //   // deleteDatabase();
        //   // await storeDataInIndexedDB(bookings, currentVenue.venueid); // Original
        //   await storeDataInIndexedDB(minimizedBookings, currentVenue.venueid); // Modified
        //   // setBookingList(tmpBookingList);
        //   console.timeEnd("dispatchToStore"); // End timing for dispatch

        //   console.timeEnd("bookings time 4 sure");
        // });
      }
      setLoading(false);
      console.timeEnd("setListener"); // End timing the entire function
    }

    setWaiting(false);
  }

  async function dispatchToStore(tempCurrentVenue) {
    console.log("tempCurrentVenue X", tempCurrentVenue);
    // alert("dispatched");
    const tmpFiltered = tempCurrentVenue.bookings.filter((item) => item.status !== "Killed");
    // alert("now C");
    const tmpSmaller = tempCurrentVenue.bookings.map((b) => ({
      ...b,
      // venue: undefined,
      // venue: b.venue ? { ...b.venue, products: undefined } : b.venue,
    }));
    // dispatch(storeCurrentVenueBookings(tempCurrentVenue.bookings));
    dispatch(storeCurrentVenueBookings(tmpSmaller));
    // alert("2");
    dispatch(storeCurrentVenue(tempCurrentVenue)); // With all bookings inside currentVenue.bookings // <=== HERE
    // dispatch(storeCurrentVenue(currentVenue)); // With no bookings inside currentVenue.bookings
    setOnline(true);
  }

  useEffect(() => {
    console.log("accessibleVenues", accessibleVenues);
  }, [accessibleVenues]);

  useEffect(() => {
    if (venueAccess[0] === "none") return;
    setFetching(true);

    console.time("getVenues");
    console.log("getting venues");
    async function loadVenues(venueAccess) {
      ensureStores(venueAccess); // Ensure the database is opened before fetching data
      let tmpAccessibleVenues = [];
      try {
        // Loop through each venueid in the venueAccess array
        for (const venueid of venueAccess) {
          // Fetch each document from the "Venues" collection based on the venueid
          const venueDoc = await getDoc(doc(db, "Venues", venueid));
          // Check if the document exists
          if (venueDoc.exists()) {
            // Push the venue data into the accessibleVenues array
            tmpAccessibleVenues.push(venueDoc.data());
          } else {
            console.error(`No venue found for venueid: ${venueid}`);
          }
        }
        // Return or set accessibleVenues as needed
        // console.log("Accessible Venues:", accessibleVenues);
        setAccessibleVenues(tmpAccessibleVenues);
        console.timeEnd("getVenues");
        setFetching(false);
        return tmpAccessibleVenues; // Return or use accessibleVenues accordingly
      } catch (error) {
        console.error("Error loading venues:", error);
      }
    }
    // Call the loadVenues function with the venueAccess array
    loadVenues(venueAccess);
  }, [venueAccess]);

  // When accessible Venues are loaded, set the currentVenue to the last venue in the array
  useEffect(() => {
    if (Object.keys(accessibleVenues[0]).length === 0 && accessibleVenues[0].constructor === Object) return; // If accessibleVenues is empty, return
    console.log("accessibleVenues", accessibleVenues);
    handleChange(accessibleVenues[0] && accessibleVenues[accessibleVenues.length - 1].venueid);
    // dispatch(storeCurrentVenue(accessibleVenues[0] && accessibleVenues[accessibleVenues.length - 1]));
    dispatch(storeAccessibleVenues(accessibleVenues));
  }, [accessibleVenues]);

  useEffect(() => {
    if (currentVenue && currentVenue.venueid === "none") return;
    // alert("currentVenue: " + currentVenue.venueid);

    const updatedAccessibleVenues = updateAccessibleVenuesWhenCurrentVenue(accessibleVenues, currentVenue);
    // setAccessibleVenues(updatedAccessibleVenues);

    if (Object.keys(currentVenue).length === 0 && currentVenue.constructor === Object) return;
    console.log("currentVenuetickets", currentVenue);
    if (!currentVenue) {
      alert("No currentVenue");
      return;
    }

    const ticketVenueId = currentVenue.ticketVenueId && currentVenue.ticketVenueId;
    if (!ticketVenueId) return;
    // alert("ticketVenueId: " + ticketVenueId);
    console.log("fetching ticket summary for", ticketVenueId);
    fetchTicketSummary(ticketVenueId);

    async function fetchTicketSummary(ticketVenueId) {
      console.time("fetchTickets");
      try {
        const ticketSummaryRef = doc(db, "TicketSummaries", ticketVenueId);
        const ticketSummarySnap = await getDoc(ticketSummaryRef);

        if (!ticketSummarySnap.exists()) {
          console.warn("No ticket summary found for this venue");
          return;
        }

        console.log("Ticket Document data:", ticketSummarySnap.data());
        const ticketSummaryData = ticketSummarySnap.data();
        dispatch(storeCurrentTicketSummary(ticketSummaryData));
      } catch (error) {
        console.error("Error fetching ticket summary:", error);
      } finally {
      }
    }
    console.time("fetchTickets");
  }, [currentVenue]);

  // REMOVE?
  // useEffect(() => {
  //   if (Object.keys(currentTicketSummary[0]).length === 0 && currentTicketSummary[0].constructor === Object) return;
  //   currentTicketSummary.length > 0 && console.log("currentTicketSummary:", currentTicketSummary);
  // }, [currentTicketSummary]);

  useEffect(() => {
    if (!online) console.time("Now"); // Measure time to initiate the snapshot listener
    if (online) console.timeEnd("Now"); // Measure time to initiate the snapshot listener
  }, [online]);

  useEffect(() => {
    console.log("loading", loading);
  }, [loading]);

  return (
    <div>
      <div>
        {superOwner && (
          <>
            cache size: <ObjectSize dataObject={cached} />
            <Button onClick={() => deleteDatabase()} size="small" variant="outlined">
              Clear Cache
            </Button>
          </>
        )}
      </div>

      <div className="bottomLoading">
        {fetching && <>fetching</>}
        {waiting && <>waiting</>}
        {loadingBookings > 0 && loadingBookings < 100 && (
          <LinearProgress variant="determinate" value={loadingBookings} style={{ opacity: "0.3" }} />
        )}
      </div>
      <Box sx={{ minWidth: 100, maxWidth: 200, height: 20 }} mt={10}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" style={{ marginTop: "-25px" }} shrink={true}>
            {!loading ? (
              <>Select Venue</>
            ) : (
              <>
                {/* <CircularProgress size={15} />  */}
                Loading venues
                <LoadingDots speed="300" />
              </>
            )}
          </InputLabel>
          {console.log("VALUE:", currentVenue)}

          <span
            className="onlineIndicator"
            style={{ width: "10px", height: "10px", background: online ? "green" : "orange", borderRadius: "50%" }}
          ></span>
          {/* <Tooltip title={loading ? "Wait until bookings finished loading" : "Select Venue"} placement="top"> */}
          <Select
            value={value}
            // renderValue={shortText(value, 10)}
            className="selectVenue"
            labelId="select-venue"
            id="select-venue"
            onChange={(e) => handleChange(e.target.value)}
            fullWidth={true}
            sx={{ height: "30px", color: "transparent" }}
            disabled={!online}
          >
            {loading && (
              <MenuItem value={null} name="Loading venues">
                {" "}
                <CircularProgress size={15} />{" "}
              </MenuItem>
            )}
            {accessibleVenues.map((venue) => (
              <MenuItem value={venue.venueid} name={venue.venue}>
                <Avatar
                  size="small"
                  alt={venue.venue}
                  src={venue.venueImage}
                  sx={{
                    marginRight: "10px",
                    float: "left",
                    border: "1px solid",
                  }}
                />
                {(loading || !online || loadingBookings < 100) && (
                  <CircularProgress
                    size={43}
                    sx={{
                      position: "absolute",
                      top: "-42%",
                      left: "6.85%",
                      transform: "translate(-50%, -50%)",
                      zIndex: -1, // Ensure it's behind the avatar if needed
                      opacity: "0.3",
                    }}
                  />
                )}
                <p style={{ color: "white", display: "flex", marginTop: "15px" }}>{venue.venue && shortText(venue.venue, 16)}</p>
              </MenuItem>
            ))}

            <Divider />
            <MenuItem p={2}>
              <AddVenue location="selectVenue" />
            </MenuItem>
          </Select>
          {/* </Tooltip> */}
        </FormControl>
      </Box>
    </div>
  );
};

export default SelectVenue;
