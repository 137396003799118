/** @format */

// TO DO
// Dispatch formdata to Redux on update
// CheckIfExists needs to be a cloud function for security reasons.Fix it!

/** @format */

import React, { useEffect, useState } from "react";
import { venueObj } from "../dbObjects.js/dbVenue";
// import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { productObj } from "../dbObjects.js/dbProducts";
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  IconButton,
  Zoom,
  InputAdornment,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Divider,
} from "@mui/material";
import { DatePicker, StaticTimePicker, TimePicker } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import { useDispatch, useSelector } from "react-redux";
import Products from "../components/Forms/Products";
import AddLocation from "../components/Forms/AddLocation";
import { collection, deleteDoc, doc, getDocs, setDoc, updateDoc } from "firebase/firestore";
import { createId, db, updateDocument } from "../firebase";
import { ImageUploader } from "../components/UI helpers/ImageUpload/ImageUploader";
import InfoBadge from "../components/MiscUIComponents/InfoBadge";
import ConfirmDialog from "./ConfirmDialog";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { artistObj } from "../dbObjects.js/dbArtists";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PhoneInput from "../components/UI helpers/PhoneInput";
import { AES, enc } from "crypto-js";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import {
  storeCurrentAgent,
  storeCurrentArtist,
  storeCurrentBooking,
  storeCurrentUser,
  storeCurrentVenue,
} from "../features/venueFilter/venueFilterSlice";
import DisplayObject from "./displayObject";
import { ConstructionOutlined } from "@mui/icons-material";
import { addNewVenueToUsersVenueAccess } from "./AddNewVenueToUsersVenueAccess";
import CheckIfEmailExists from "../utils/CheckIfEmailExists";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IBANTextField from "../components/UI helpers/IBANTextField";
import BICTextField from "../components/UI helpers/BICTextField";
import VenueAdditionals from "../components/MiscUIComponents/VenueAdditionals";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import VenueOverviewDisplay from "../components/MiscUIComponents/VenueOverviewDisplay";
import ProductsDisplay from "./productsDisplay";
import ProductsWithFeatures from "../components/Forms/ProductsWithFeatures";
import TicketServiceInput from "../components/UI helpers/TicketServiceInput";
import CountryInput from "../components/UI helpers/CountryInput";
import SubscriptionInput from "./SubscriptionInput";
import StandardVatSelect from "./StandardVatSelect";
import LogoUpload from "../components/MiscUIComponents/LogoUpload";
import MultiImagesUpload from "../components/MiscUIComponents/MultiImagesUpload";
import PdfUploader from "../components/MiscUIComponents/PdfUploader";
import validateEmail from "../components/MiscUIComponents/ValidateEmail";
import { validate } from "uuid";
import PaymentSlider from "./PaymentSlider";
import CancelationSlider from "./CancelationSlider";
import AgreementAddendum from "../components/Documents/AgreementAddendum";

// import { set } from "date-fns";
// import { TimeClock } from "@mui/x-date-pickers/TimeClock";

const GeneralVenueForm = (props) => {
  // const alertColor = "#00ffd455";
  const alertColor = "red";
  const navigateAfter = props.navigateAfter;
  const secretKey = process.env.REACT_APP_FORM_KEY; // Replace with your secret key
  const [formFunction, setFormFunction] = useState(props.formFunction);
  // let formArray = props.form;
  const [formArray, setFormArray] = useState(props.form);
  const lowestPriorityShown = props.priority ? props.priority : 20;
  const formName = props.formName;
  const currentUser = useSelector((state) => state.counter.currentUser);
  const currentBooking = useSelector((state) => state.counter.currentBooking);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentArtist = useSelector((state) => state.counter.currentArtist);
  const currentAgent = useSelector((state) => state.counter.currentAgent);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const superOwner = useSelector((state) => state.counter.superOwner);
  const [formData, setFormData] = useState([currentVenue && { currentVenue }]);
  const [inRedux, setInRedux] = useState();
  // const [formData, setFormData] = useState();
  const [documentid, setDocumentid] = useState();
  const [deductFromTickets, setDeductFromTickets] = useState(false);
  const [ticketApiKey, setTicketApiKey] = useState("");
  const [ticketService, setTicketService] = useState("None");
  const [phoneNumber, setPhoneNumber] = useState(formData.phone);
  const [IBAN, setIBAN] = useState(formData.IBAN);
  const [BIC, setBIC] = useState(formData.BIC);
  const [matchingPassword, setMatchingPassword] = useState();
  const [checkSocialId, setCheckSocialId] = useState();
  const [emailOk, setEmailOk] = useState(true);
  const [features, setFeatures] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleTimeChange = (e, id) => {
    console.log(e.$d.toLocaleTimeString().slice(0, 5));
    let value = e.$d;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };
  const disabledFields = props.disabledFields;

  const xs = 12;
  const md = 3;
  const sm = 4;

  useEffect(
    function () {
      if (location.state && location.state.activeTab) {
        setValue(location.state.activeTab);
      }
    },
    [location]
  );

  // If fields are included in props, only show those fields
  // const [fieldArray, setFieldArray] = useState([props.fields]);
  const fieldArray = props.fields;

  useEffect(() => {
    if (fieldArray) {
      // const filteredFormArray = formArray.filter((obj) => fieldArray.includes(obj.id));
      const filteredFormArray = [];
      for (let i = 0; i < formArray.length; i++) {
        const fieldId = formArray[i].id;
        if (fieldArray.includes(fieldId)) {
          filteredFormArray.push(formArray[i]);
          console.log("new formarray", filteredFormArray);
        }
      }
      setFormArray(filteredFormArray);
    }
  }, []);

  const handleChange = (event, id, type) => {
    // console.log(event.target.value, id, formData);
    let value;
    console.log("HERE=>", event, id, type);
    if (type === "value") {
      value = event; //in this case event represents a value instead of an event object
    } else {
      // if type is a "switch".
      type === "text" && (value = event.target.value);
      type === "switch" ? (value = event.target.checked) : (value = event.target.value);
    }
    // alert("value: " + event.target.value);
    console.log("value: " + value);
    // Encrypt certain values before storage
    if (
      id === "socialId" ||
      // id === "IBAN" || id === "BIC" ||
      id === "password" ||
      id === "passwordConfirm"
    ) {
      const encryptedValue = AES.encrypt(value, secretKey).toString();
      value = encryptedValue;
    }

    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleToggleButton = (event, newValues, id) => {
    // keyValue = "preferredDays";
    console.log(id, newValues);
    setFormData((prev) => ({ ...prev, [id]: newValues }));

    // console.log(event, key, value);
    console.log(formData);
  };

  function executeCode(code) {
    return Function('"use strict";return (' + code + ")")()();
  }

  const [loading, setLoading] = useState(false);

  async function handleUpdate(collection, docid) {
    setLoading(true);
    console.log(`Storing formData to id:${docid} in collection:${collection}`, "FormData:", formData);
    console.log("features", formData.features);

    // await setDoc(doc(db, collection, docid), { ...formData }, { merge: true });
    await setDoc(doc(db, collection, docid), { ...formData }); // without merge in order to also remove stuff

    // ...and dispatch to redux and some other stuff.
    formName === "venueForm" && dispatch(storeCurrentVenue(formData));
    formName === "venueForm" && addNewVenueToUsersVenueAccess(formData, currentUser);
    // formName === "venueForm" && alert("storing");
    // formName === "venueForm" && alert(formName);
    formName === "userForm" && (await dispatch(storeCurrentUser(formData)));
    formName === "bookingForm" && (await dispatch(storeCurrentBooking(formData)));

    // navigateAfter && navigate(navigateAfter);
    // window.location.href = "https://venuehub.live/owner/home";
    setLoading(false);
  }

  async function handleDelete(collection, id) {
    console.log("Deleting:", collection, id);
    await deleteDoc(doc(db, collection, id));
    navigate(-1);
  }

  //Check if email is an email address, and is free or already in use
  async function checkEmail(email) {
    //first check that the format is an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      //if not the correct format
      setEmailOk(false);
      setFormData((prev) => ({ ...prev, email: "Wrong format..." }));
      setTimeout(() => {
        setFormData((prev) => ({ ...prev, email: "" }));
      }, 3000);
      return;
    }

    //then if format is correct, check if already in use
    if ((await CheckIfEmailExists(email)) === "Exists") {
      setEmailOk(false);
      await setTimeout(() => {
        setFormData((prev) => ({ ...prev, email: "This email is already in use..." }));
      }, 100);
      setTimeout(() => {
        setFormData((prev) => ({ ...prev, email: "" }));
      }, 1000);
    }
    // if format correct and email not in use...
    else {
      setEmailOk(true);
    }
  }

  // Send formdata back via props (for instance when using stepper to show form)
  useEffect(() => {
    formName === "venueForm" && props.setVenueData(formData);
    formName === "userForm" && props.setUserData(formData);
    formName === "bookingForm" && props.setBookingData(formData);
  }, [formData]);

  // Depending on wich form use corresponding redux storage
  useEffect(() => {
    formName === "venueForm" && setFormData(currentVenue);
    formName === "ownerForm" && setFormData(currentUser);
    formName === "userForm" && setFormData(currentUser);
    formName === "agentForm" && setFormData(currentAgent);
    formName === "artistForm" && setFormData(currentArtist);
    formName === "bookingForm" && setFormData(currentBooking);
  }, [currentVenue, currentArtist, currentUser, currentAgent, currentBooking]);

  useEffect(() => {
    phoneNumber && setFormData((prev) => ({ ...prev, phone: phoneNumber }));
  }, [phoneNumber]);

  useEffect(() => {
    IBAN && setFormData((prev) => ({ ...prev, IBAN: IBAN }));
    console.log("4", IBAN);
  }, [IBAN]);

  useEffect(() => {
    BIC && setFormData((prev) => ({ ...prev, BIC: BIC }));
  }, [BIC]);

  //Validate Password
  useEffect(() => {
    const password = formData.password && AES.decrypt(formData.password, secretKey).toString(enc.Utf8);
    const passwordConfirm = formData.passwordConfirm && AES.decrypt(formData.passwordConfirm, secretKey).toString(enc.Utf8);
    if (password === passwordConfirm) {
      setMatchingPassword(true);
    }
    if (password !== passwordConfirm) {
      setMatchingPassword(false);
    }
  }, [formData.password, formData.passwordConfirm]);

  //Validate socialId is 12 letters
  useEffect(() => {
    const numberRegex = /^\d{12}$/;
    let tmp = formData.socialId && AES.decrypt(formData.socialId, secretKey).toString(enc.Utf8);
    if (!numberRegex.test(tmp)) {
      setCheckSocialId(false);
    } else {
      setCheckSocialId(true);
    }
  }, [formData.socialId]);

  //if props.validation is needed (to turn on or off Submitbutton) this checks if email,socialId and passwords are correct and returns true or false
  useEffect(() => {
    // console.log({ emailOk, checkSocialId, matchingPassword });
    if (emailOk && checkSocialId && matchingPassword) {
      props.setValidation && props.setValidation(true);
    } else {
      props.setValidation && props.setValidation(false);
    }
  }, [emailOk, checkSocialId, matchingPassword]);
  // }, []);

  // if venueForm make sure to remove bookings otherwise formData gets too big to store...
  useEffect(() => {
    if (formName === "venueForm") {
      setFormData((prev) => ({ ...prev, bookings: null }));
    }
    console.log(formData);
  }, [formData.bookings]);

  const renderFormField = (field) => {
    switch (field.type) {
      case "productsComponent": {
        return (
          <Grid item sx={12} className="formSectionLabel" style={{ width: "100%" }}>
            {/* <Products /> */}
            <ProductsWithFeatures setValue={setValue} formData={formData} setFormData={setFormData} />
          </Grid>
        );
      }

      case "addLocationComponent": {
        return (
          <Grid item sx={12} className="formSectionLabel" style={{ width: "100%" }}>
            <AddLocation setFormValues={setFormData} fieldPrepend={field.fieldPrepend} />
          </Grid>
        );
      }

      case "paymentSlider": {
        return (
          <Grid item sx={12} className="formSectionLabel">
            {/* <AddLocation setFormValues={setFormData} fieldPrepend={field.fieldPrepend} /> */}
            <PaymentSlider bookingData={formData} setBookingData={setFormData} currentVenue={currentVenue} />
          </Grid>
        );
      }

      case "cancelationSlider": {
        return (
          <Grid item sx={12} className="formSectionLabel">
            {/* <AddLocation setFormValues={setFormData} fieldPrepend={field.fieldPrepend} /> */}
            <CancelationSlider bookingData={formData} setBookingData={setFormData} currentVenue={currentVenue} />
          </Grid>
        );
      }

      case "Section":
        return (
          <div style={{ width: "100%" }}>
            <Grid item xs={12} className="formSectionLabel" style={{ width: "100%" }}>
              {field.section}
            </Grid>
          </div>
        );

      case "select":
        return (
          <FormControl>
            <InputLabel>{field.label}</InputLabel>
            <Select
              size="small"
              value={formData[field.id]}
              onChange={(e) => {
                handleChange(e, field.id);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={field.style && field.style}
              {...field}
            >
              {field.options.map((option) => (
                <MenuItem value={option} name={field.id} field={field.id}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      case "text":
      case "number":
        return (
          <TextField
            InputLabelProps={{
              shrink: formData[field.id] || field.defaultValue ? true : false,
            }}
            size="small"
            id={field.id}
            label={field.label}
            required={field.required}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            type={field.type}
            value={formData[field.id]}
            disabled={field.disabled}
            // fullWidth
            onChange={(e) => handleChange(e, field.id)}
            {...field}
            style={{ background: formData[field.id] ? "transparent" : "#ffa50055" }}
          />
        );

      case "IBAN":
        console.log("5", formData[field.id]);
        return <IBANTextField formValue={IBAN || formData[field.id] ? formData[field.id] : ""} setIBAN={setIBAN} />;

      case "BIC":
        return <BICTextField formValue={BIC || formData[field.id] ? formData[field.id] : ""} setBIC={setBIC} />;

      case "encrypted":
        return (
          <>
            <Tooltip
              title={
                (field.id === "socialId" && !checkSocialId && "Enter Social ID with 12 numbers") ||
                (field.id === "password" && !matchingPassword && "Make sure Password and Confirm password is matching") ||
                (field.id === "passwordConfirm" && !matchingPassword && "Make sure Password and Confirm password is matching")
              }
            >
              <TextField
                size="small"
                id={field.id}
                required={field.required}
                label={field.label}
                InputLabelProps={{ shrink: field.label.length > 0 }}
                value={formData[field.id] ? AES.decrypt(formData[field.id], secretKey).toString(enc.Utf8) : ""}
                disabled={field.disabled}
                type={field.id === "password" || field.id === "passwordConfirm" ? "password" : ""}
                onChange={(e) => handleChange(e, field.id)}
                style={{
                  borderRadius: "10px",
                  boxShadow:
                    ((field.id === "password" || field.id === "passwordConfirm") && !matchingPassword) ||
                    (field.id === "socialId" && !checkSocialId)
                      ? `0px 0px 4px ${alertColor}`
                      : "",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {field.id === "socialId" && checkSocialId && <CheckCircleIcon style={{ color: "#0080006b" }} />}
                      {(field.id === "password" || field.id === "passwordConfirm") && matchingPassword && (
                        <CheckCircleIcon style={{ color: "#0080006b" }} />
                      )}
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="Encrypted">
                        <EnhancedEncryptionIcon style={{ color: "#555", zIndex: "100000" }} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Tooltip>
          </>
        );

      case "password":
        return (
          <TextField
            size="small"
            id={field.id}
            label={field.label}
            placeholder={field.placeholder}
            defaultValue={field.defaultValue}
            type={field.type}
            value={formData[field.id]}
            disabled={field.disabled}
            onChange={(e) => handleChange(e, field.id)}
            {...field}
            InputLabelProps={{
              shrink: formData[field.id] ? true : false,
            }}
          />
        );

      case "email":
        return (
          <>
            {console.log("emailvalidation", validateEmail(formData[field.id]))}
            <TextField
              size="small"
              id={field.id}
              label={field.label}
              placeholder={field.placeholder}
              // defaultValue={field.defaultValue}
              type={field.type}
              value={formData[field.id]}
              disabled={field.disabled}
              onChange={(e) => handleChange(e, field.id)}
              onBlur={(e) => field.checkIfEmailExists && checkEmail(formData[field.id])}
              style={{
                width: "300px",
                borderRadius: "10px",
                boxShadow: !emailOk && `0px 0px 4px ${alertColor}`,
                background: validateEmail(formData[field.id]) ? "transparent" : "#ffa50055",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {field.checkIfEmailExists && emailOk && <CheckCircleIcon style={{ color: "#0080006b" }} />}
                  </InputAdornment>
                ),
              }}
            />
          </>
        );

      case "phone":
        return <PhoneInput formValue={formData[field.id]} setPhoneNumber={setPhoneNumber} />;

      case "logoUpload":
        return <LogoUpload formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />;

      case "multiImagesUpload":
        return <MultiImagesUpload formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />;

      case "pdfUploader":
        return <PdfUploader handleChange={handleChange} handleUpdate={handleUpdate} />;

      case "ticketService":
        return <TicketServiceInput formData={formData} handleChange={handleChange} handleUpdate={handleUpdate} />;

      case "country":
        return <CountryInput formData={formData} handleChange={handleChange} />;

      case "textarea":
        return (
          <TextField
            name={field.name}
            label={field.label}
            value={formData[field.id] || ""}
            multiline={field.multiline}
            rows={field.rows}
            onChange={(event) => setFormData({ ...formData, [field.name]: event.target.value })}
          />
        );

      case "slider":
        return (
          <Slider
            name={field.label}
            // value={formData[field.id] || "50"}
            min={field.min}
            max={field.max}
            step={field.step}
            onChange={(e) => handleChange(e, field.id)}
            style={{ width: "200px" }}
            valueLabelDisplay={field.valueLabelDisplay}
          />
        );

      case "date":
        return (
          <DatePicker
            name={field.name}
            label={field.label}
            value={formData[field.name] || null}
            onChange={(newValue) => setFormData({ ...formData, [field.name]: newValue })}
            renderInput={(params) => <TextField {...params} />}
          />
        );

      case "range":
        return (
          <DateRangePicker
            startText={field.startText}
            endText={field.endText}
            value={formData[field.name] || [null, null]}
            onChange={(newValue) => setFormData({ ...formData, [field.name]: newValue })}
            renderInput={(startProps, endProps) => (
              <>
                <TextField {...startProps} />
                <TextField {...endProps} />
              </>
            )}
          />
        );

      case "time":
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl>
              <TextField
                {...field}
                defaultValue={currentVenue[field.id]}
                value={formData[field.id]}
                onChange={(e) => handleChange(field.id, e.target.value)}
                size="small"
                style={{ width: "150px" }}
              />
              {/* <MobileTimePicker /> */}
              {/* <StaticTimePicker
                label={field.id}
                value={formData[field.id]}
                minutesStep={5}
                defaultValue={new Date("Mon Apr 17 2023 16:25:13")}
                onChange={(e) => handleTimeChange(e, field.id)}
                ampm={false}
                className={field.section}
              /> */}
            </FormControl>
          </LocalizationProvider>
        );

      case "Button":
        return (
          <Button size="small" variant="contained" color="primary" id={field.id}>
            {field.label}
          </Button>
        );

      case "switch":
        return (
          <FormControlLabel
            // id={field.id}
            control={
              <Switch checked={formData[field.id] ? formData[field.id] : false} onChange={(e) => handleChange(e, field.id, field.type)} />
            }
            label={field.label}
          />
        );

      case "standardVat":
        return currentVenue.country ? <StandardVatSelect formData={formData} handleChange={handleChange} /> : "";

      case "toggleButton" || "checkbox":
        return (
          <Grid item className={field.id}>
            <InputLabel className="inputLabel">{field.label}</InputLabel>
            <ToggleButtonGroup
              style={{ padding: "20px" }}
              size="small"
              exclusive={field.exclusive}
              key={field.id}
              value={formData[field.id]}
              defaultValue={field.defaultValue && field.defaultValue}
              onChange={(event, newValues) => handleToggleButton(event, newValues, field.id)}
            >
              {field.options &&
                field.options.map((option) => (
                  <ToggleButton key="preferredDays" value={option} color="primary">
                    {option}
                  </ToggleButton>
                ))}
            </ToggleButtonGroup>
          </Grid>
        );

      case "url":
        return (
          <TextField
            {...field}
            value={formData[field.id]}
            className={field.id}
            InputLabelProps={{
              // shrink: formData[field.id] ? true : false,
              shrink: true,
            }}
          />
        );

      case "subscriptionInput":
        return <SubscriptionInput currentVenue={currentVenue} />;
        return;

      case "imageUpload":
        return (
          <Grid
            item
            className="imageUpload"
            direction="column"
            p={2}
            xs={12}
            style={{
              background: "linear-gradient(0deg, #111, #222)",
              border: "1px solid #555",
              borderRadius: "10px",
              boxShadow: " 5px 5px 10px black",
              minWidth: "500px",
              maxWidth: "500px",
            }}
          >
            <ImageUploader storageFolder={field.storage} formImageKey={field.id} setFormValues={setFormData} formValues={formData} />
            {formData[field.id] && (
              <Button
                style={{ marginTop: "10px" }}
                onClick={() => {
                  // console.log("removing image");
                  setFormData({
                    ...formData,
                    [field.id]: "",
                  });
                }}
              >
                Change Image
              </Button>
            )}
          </Grid>
        );

      case "fileUpload":
        return (
          <Grid
            item
            className="fileUpload"
            direction="column"
            p={2}
            xs={12}
            style={{
              background: "linear-gradient(0deg, #111, #222)",
              border: "1px solid #555",
              borderRadius: "10px",
              boxShadow: " 5px 5px 10px black",
              minWidth: "100px",
            }}
          >
            <ImageUploader storageFolder={field.storage} formImageKey={field.id} setFormData={setFormData} formValues={formData} />
            {formData[field.id] && (
              <Button
                onClick={() => {
                  // console.log("removing image");
                  setFormData({
                    ...formData,
                    [field.id]: "",
                  });
                }}
              >
                Change Image
              </Button>
            )}
          </Grid>
        );

      case "VenueAdditionals":
        return (
          <Grid item xs={12}>
            {/* <VenueAdditionals setFeatures={setFeatures} /> */}
            <VenueAdditionals formData={formData} setFormData={setFormData} />
          </Grid>
        );

      case "update":
        return (
          <Grid item xs={12}>
            <Button
              startIcon={loading && <CircularProgress sx={{ zoom: "0.4" }} style={{ marginRight: "5px" }} />}
              fullWidth
              variant="contained"
              color="primary"
              id={field.id}
              onClick={() => handleUpdate(field.collection, formData.documentid)}
            >
              {field.label}
            </Button>
          </Grid>
        );

      case "goback":
        return (
          <Grid item xs={12}>
            <Button onClick={() => navigate(-1)} variant="outlined" fullWidth>
              Cancel
            </Button>
          </Grid>
        );

      case "deleteVenue":
        return (
          <Grid item xs={12}>
            {superOwner && (
              <ConfirmDialog onConfirm={() => handleDelete(field.collection, currentVenue.venueid)} noButton>
                <Button fullWidth>
                  <DeleteIcon fontSize="small" style={{ marginRight: "10px" }} />
                  Delete
                </Button>
              </ConfirmDialog>
            )}
          </Grid>
        );

      default:
        return null;
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState("Account");

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <form>
      {/* {inRedux} */}
      {/* {console.log("FORMDATA", formData)} */}
      {/* <DisplayObject data={formData} /> */}
      <Box>
        <TabContext value={value}>
          <Box xs={12} sx={{ borderBottom: 1, borderColor: "divider" }} maxWidth={{ xs: "100vw" }}>
            {/* {currentVenue.venueid} */}
            <TabList
              onChange={handleChangeTab}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              sx={{
                // display: "flex",
                // flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              {/* <Tab label="Overview" value="Overview" /> */}
              <Tab label="Products" value="Products" />
              <Tab label="Modules" value="Features" />
              <Tab label="Agreement" value="Agreement" />
              <Tab label="Notifications" value="Notifications" />
              <Tab label="Account" value="Account" />
              <Tab label="Media" value="Media" />

              <Tab label="Tickets" value="Tickets" />
            </TabList>
          </Box>
          <TabPanel value="Overview">
            <Grid container xs={12}>
              {/* <VenueOverviewDisplay formData={formData} setValue={setValue} /> */}
            </Grid>
          </TabPanel>
          <TabPanel value="Products">
            {/* <Grid container gap={2} xs={12} sm={10} md={10} mt={4}>
              <ProductsDisplay />
            </Grid> */}
            <Grid container gap={2} xs={12} sm={10} md={10}>
              {formData.documentid &&
                formArray.map((field, index) => (
                  <>
                    {(field.section === "" || field.section === "Products") && field.priority <= lowestPriorityShown ? (
                      <Grid item key={index} xs={6} mt={4}>
                        <Tooltip title={field.info} placement="top" arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                          {renderFormField(field)}
                        </Tooltip>
                      </Grid>
                    ) : null}
                    <div className="BottomButtons-Bottom">{field.section === "Bottom Buttons" && <div>{renderFormField(field)}</div>}</div>
                  </>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value="Features">
            <Grid container gap={2} xs={12} sm={10} md={10} mt={4}>
              {formData.documentid &&
                formArray.map((field, index) => (
                  <>
                    {
                      // field.section === "Venue" ||
                      //   field.section === "Products" ||
                      // field.section === "Image" ||
                      field.section === "Venue Features" &&
                      // field.section === "Bottom Buttons"
                      field.priority <= lowestPriorityShown ? (
                        <Grid container style={{ display: "flex", flexDirection: "row" }}>
                          <Grid item key={index} xs={12}>
                            <Tooltip title={field.info} placement="top" arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                              {renderFormField(field)}
                            </Tooltip>
                          </Grid>
                        </Grid>
                      ) : null
                    }
                    {/* {field.section === "Bottom Buttons" && <div style={{ marginTop: "100px" }}>{renderFormField(field)}</div>} */}
                  </>
                ))}
            </Grid>
          </TabPanel>

          <TabPanel value="Agreement">
            {/* <Grid container gap={2} xs={12} sm={10} md={10} mt={4}> */}
            <Grid container xs={12} style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
              {formData.documentid &&
                formArray.map((field, index) => (
                  <>
                    {(field.section === "" ||
                      field.section === "Payment" ||
                      field.section === "Cancelations" ||
                      field.section === "Agreement Addendum" ||
                      (superOwner && field.section === "Tickets") ||
                      field.section === "Bank Account" ||
                      field.section === "Administrative") &&
                    field.priority <= lowestPriorityShown ? (
                      // <Grid item key={index} xs={12}>
                      // <Grid item key={index} xs={xs} md={md} sm={sm}>
                      // <Grid item key={index} xs={12}>
                      <Grid item xs={12}>
                        <Tooltip
                          title={field.info}
                          placement={field.placement}
                          arrow
                          enterDelay={500}
                          leaveDelay={10}
                          TransitionComponent={Zoom}
                        >
                          {renderFormField(field)}
                        </Tooltip>
                      </Grid>
                    ) : null}
                    {field.section === "Bottom Buttons" && <div style={{ marginTop: "100px" }}>{renderFormField(field)}</div>}
                  </>
                ))}
            </Grid>
          </TabPanel>

          <TabPanel value="Notifications">
            <Grid container gap={2} xs={12} sm={10} md={10} mt={4}>
              {formData.documentid &&
                formArray.map((field, index) => (
                  <>
                    {(field.section === "" || field.section === "Notifications") && field.priority <= lowestPriorityShown ? (
                      <Grid item key={index} xs={12}>
                        <Tooltip title={field.info} followCursor arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                          {renderFormField(field)} <description>{field.info}</description>
                        </Tooltip>
                      </Grid>
                    ) : null}
                    {field.section === "Bottom Buttons" && <div style={{ marginTop: "100px" }}>{renderFormField(field)}</div>}
                  </>
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value="Account">
            {/* <Grid container gap={2} xs={12} sm={10} md={10} mt={4}> */}
            <div>
              {/* <Grid container spacing={2} xs={10}> */}
              {/* <Grid container xs={12} style={{ display: "flex", flexDirection: "row", gap: "15px" }}> */}
              <Grid container xs={12} md={11} style={{ display: "flex", flexDirection: "row", gap: "15px", marginBottom: "15px" }}>
                <div onClick={(e) => handleChangeTab(e, "Media")}>
                  <img src={currentVenue.venueImage} width="300px" height="auto" />
                </div>
                {formData.documentid &&
                  formArray.map((field, index) => (
                    // <Grid container xs={12} style={{ display: "flex", flexDirection: "row", gap: "5px", marginBottom: "5px" }}>
                    <Grid item>
                      {(field.section === "" ||
                        field.section === "Contact" ||
                        field.section === "Subscription" ||
                        field.section === "Location" ||
                        field.section === "Postal" ||
                        field.section === "Account" ||
                        field.section === "Venue" ||
                        // field.section === "Image" ||
                        field.section === "Company" ||
                        field.section === "Public" ||
                        field.section === "Messages") &&
                      field.priority <= lowestPriorityShown ? (
                        <Tooltip title={field.info} placement="top" arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                          {renderFormField(field)}
                        </Tooltip>
                      ) : null}
                      {field.section === "Bottom Buttons" && <div style={{ marginTop: "100px" }}>{renderFormField(field)}</div>}
                    </Grid>
                  ))}
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value="Media">
            {/* <Grid container gap={2} xs={12} sm={10} md={10} mt={4}> */}
            <div>
              {/* <Grid container spacing={2} xs={10}> */}
              {/* <Grid container xs={12} style={{ display: "flex", flexDirection: "row", gap: "15px" }}> */}
              <Grid container xs={12} md={11} style={{ display: "flex", flexDirection: "row", gap: "15px", marginBottom: "15px" }}>
                {formData.documentid &&
                  formArray.map((field, index) => (
                    // <Grid container xs={12} style={{ display: "flex", flexDirection: "row", gap: "5px", marginBottom: "5px" }}>
                    <Grid item>
                      {(field.section === "" ||
                        field.section === "Logotype" ||
                        field.section === "Image" ||
                        field.section === "Images" ||
                        field.section === "Attachments") &&
                      field.priority <= lowestPriorityShown ? (
                        <Tooltip title={field.info} placement="top" arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                          {renderFormField(field)}
                        </Tooltip>
                      ) : null}
                      {field.section === "Bottom Buttons" && <div style={{ marginTop: "100px" }}>{renderFormField(field)}</div>}
                      {/* {field.section === "Bottom Buttons" && <div style={{ marginTop: "100px" }}>{renderFormField(field)}</div>} */}
                    </Grid>
                  ))}
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value="Tickets">
            {/* <Grid container gap={2} xs={12} sm={10} md={10} mt={4}> */}
            <div>
              {/* <Grid container spacing={2} xs={10}> */}
              {/* <Grid container xs={12} style={{ display: "flex", flexDirection: "row", gap: "15px" }}> */}
              <Grid container xs={12} md={11} style={{ display: "flex", flexDirection: "row", gap: "15px", marginBottom: "15px" }}>
                {formData.documentid &&
                  formArray.map((field, index) => (
                    // <Grid container xs={12} style={{ display: "flex", flexDirection: "row", gap: "5px", marginBottom: "5px" }}>
                    <Grid item>
                      {(field.section === "" || field.section === "Tickets") && field.priority <= lowestPriorityShown ? (
                        <Tooltip title={field.info} placement="top" arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                          {renderFormField(field)}
                        </Tooltip>
                      ) : null}
                      {/* {field.section === "Bottom Buttons" && <div style={{ marginTop: "100px" }}>{renderFormField(field)}</div>} */}
                    </Grid>
                  ))}
              </Grid>
            </div>
          </TabPanel>
        </TabContext>
        {console.log("formData", formData)}
        {console.log("formArray", formArray)}
      </Box>
      {/* <Grid container gap={2} xs={12} sm={10} md={10} mt={4} className={"generalForm " + formName}>
        {formData.documentid &&
          formArray.map((field, index) => (
            <Grid item key={index} style={{ display: "flex" }}>
              {field.priority <= lowestPriorityShown && (
                <Tooltip title={field.info} placement="top" arrow enterDelay={500} leaveDelay={10} TransitionComponent={Zoom}>
                  {renderFormField(field)}
                </Tooltip>
              )}
            </Grid>
          ))}
      </Grid> */}
    </form>
  );
};

export default GeneralVenueForm;
