/** @format */

import { ca } from "date-fns/locale";

export function calculateEmissionsBasedOnDistance(distanceKm = 0) {
  const emissionFactors = {
    car: 135, // g CO2e/passenger-km
    bus: 92.46, // g CO2e/passenger-km
    train: 3.822, // g CO2e/passenger-km
    bicycle: 0, // g CO2e/passenger-km
    plane: 185.9171396, // g CO2e/passenger-km
  };

  let emissionsKg = 0;
  let carDistance = 0;
  let bicycleDistance = 0;
  let busDistance = 0;
  let trainDistance = 0;
  let planeDistance = 0;
  let carShare = 0;
  let bicycleShare = 0;
  let busShare = 0;
  let trainShare = 0;
  let planeShare = 0;

  if (distanceKm < 10) {
    carShare = 0.05;
    bicycleShare = 0.15;
    busShare = 0.2;
    trainShare = 0.6;
    planeShare = 0.0;

    // Calculate emissions based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  }
  if (distanceKm < 20 && distanceKm >= 10) {
    carShare = 0.2;
    bicycleShare = 0.0;
    busShare = 0.2;
    trainShare = 0.6;
    planeShare = 0.0;

    // Calculate emissions based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  }
  if (distanceKm > 20 && distanceKm <= 70) {
    carShare = 0.3;
    bicycleShare = 0.0;
    busShare = 0.1;
    trainShare = 0.6;
    planeShare = 0.0;

    // Calculate emissions based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  }
  if (distanceKm > 70 && distanceKm <= 800) {
    carShare = 0.3;
    bicycleShare = 0.0;
    busShare = 0.1;
    trainShare = 0.58;
    planeShare = 0.02;

    // Calculate emissions based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  }
  if (distanceKm > 800) {
    carShare = 0.1;
    bicycleShare = 0.0;
    busShare = 0.1;
    trainShare = 0.3;
    planeShare = 0.5;

    // Calculate emissions based on distribution
    carDistance = distanceKm * carShare;
    bicycleDistance = distanceKm * bicycleShare;
    busDistance = distanceKm * busShare;
    trainDistance = distanceKm * trainShare;
    planeDistance = distanceKm * planeShare;

    emissionsKg += (carDistance * emissionFactors.car) / 1000;
    emissionsKg += (bicycleDistance * emissionFactors.bicycle) / 1000;
    emissionsKg += (busDistance * emissionFactors.bus) / 1000;
    emissionsKg += (trainDistance * emissionFactors.train) / 1000;
    emissionsKg += (planeDistance * emissionFactors.plane) / 1000;
  } else {
    // Calculate emissions based on entire distance by car
    emissionsKg = (distanceKm * emissionFactors.car) / 1000;
  }

  if (planeShare === undefined || planeDistance === undefined) {
    alert(distanceKm);
  }

  return {
    impact: emissionsKg,
    carDistance,
    bicycleDistance,
    busDistance,
    trainDistance,
    planeDistance,
    carShare,
    bicycleShare,
    busShare,
    trainShare,
    planeShare,
  };
}

// Example usage
// const distance = 8; // km
// const emissions = calculateEmissions(distance);
// console.log(`Emissions for traveling ${distance} km: ${emissions.toFixed(2)} kg CO2e`);
