/** @format */

import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import { db, getAgreementByBookingId } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { Button, Avatar, Select, MenuItem, FormControl, Grid, Switch, FormControlLabel, Tooltip } from "@mui/material";
import SelectArtist from "../../helpers/SelectArtist";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import CircleGraph from "../../components/UI helpers/CircleGraph";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import ContractMaker from "../../components/Documents/ContractMaker";
import ContractHTML from "../../components/Documents/ContractHTML";
import { storeCurrentBooking, storeCurrentBookings, storeCurrentSettings } from "../venueFilter/venueFilterSlice";
import AgreementModal from "../../components/Documents/AgreementModal";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { today } from "../../helpers/DateManagement";
import { useEffect } from "react";
import { useCallback } from "react";
import shortText from "../../helpers/shortText";
import EventTicketSummary from "../../helpers/GetTicketSalesSummary";
import BatchAgreementModal from "../../components/Documents/BatchAgreementModal";
import WeeklySalesChart from "../../helpers/SalesChartWeekly";
import { downloadDataForBackup } from "/Users/marcusdernulf/VENUEHUB/Venuehub/src/features/owner/downloadDataForBackup.js";
import ObjectSize from "../../helpers/ObjectSize";
import AgreementStatusLink from "../../components/Documents/AgreementStatusLink";

let line = [];
var randy = 0;
// const dispatch = useDispatch();
// const [currentBooking,setCurrentBooking]

// function getID(params) {
//   return `${params.row.id}`;
// }

let tempBookings = [];

export default function BookingsListed() {
  const superOwner = useSelector((state) => state.counter.superOwner);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const [bookings, setBookings] = React.useState([{ Date: "2022-01-03", Artist: "Robyn" }]);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  const cs = useSelector((state) => state.counter.currentSettings);

  // Switches for filtering
  const [showUpcoming, setShowUpcoming] = useState(cs && cs.blUpcoming);
  const [showHistory, setShowHistory] = useState(cs && cs.blHistory);
  const [showConfirmed, setShowConfirmed] = useState(cs && cs.blConfirmed);
  const [showAwaiting, setShowAwaiting] = useState(cs && cs.blAwaitingArtist);
  const [showPending, setShowPending] = useState(cs && cs.blPendingRequest);

  const discountSelector = (price, bookingid) => {
    function discountedPrice(discount) {
      const newPrice = price - (discount * price) / 100;
      storeDiscountToBooking(discount, bookingid);
    }

    function storeDiscountToBooking(discount, bookingid) {
      let temporary = { ...bookings.find((booking) => booking.bookingid === bookingid) };
      temporary.discount = discount;
      console.log("booking with discount", temporary);
    }

    return (
      <FormControl fullWidth>
        <Select defaultValue={0} sx={{ width: "100px", background: "#000" }} size="small" onChange={(e) => discountedPrice(e.target.value)}>
          <MenuItem value={0}>0 %</MenuItem>
          <MenuItem value={5}>5 %</MenuItem>
          <MenuItem value={10}>10 %</MenuItem>
          <MenuItem value={15}>15 %</MenuItem>
          <MenuItem value={20}>20 %</MenuItem>
          <MenuItem value={25}>25 %</MenuItem>
          <MenuItem value={30}>30 %</MenuItem>
          <MenuItem value={40}>40 %</MenuItem>
          <MenuItem value={50}>50 %</MenuItem>
        </Select>
      </FormControl>
    );
  };

  // Get bookings for chosen venue and add to tempVenue before dispatch below
  const [rows, setRows] = React.useState([{ id: 0, artist: "Robyn", agent: "MD", agentEmail: "mdernulf@gmail.com" }]);

  React.useEffect(() => {
    // const getData = async () => {
    //   const q = await query(collection(db, "Bookings"), where("venueid", "==", currentVenue.venueid));
    //   const data = await getDocs(q);
    //   //   await setBookings(data.docs.map((doc) => doc.data()));
    //   tempBookings = data.docs.map((doc) => doc.data());
    //   console.log(bookings);
    //   const filteredBookings = tempBookings.filter((booking) => booking.status !== "Killed");
    //   setBookings(filteredBookings);
    //   dispatch(storeCurrentBookings(filteredBookings));
    // };
    // getData();
    setBookings(currentVenueBookings);
  }, [currentVenue.venueid]);

  // React.useEffect(() => {
  //   let line = [];
  //   bookings.map((b, id) =>
  //     line.push({
  //       id: id,
  //       bookingid: b.bookingid,
  //       artist: b.artist,
  //       agent: b.agent,
  //       date: b.date,
  //       agentEmail: b.agentEmail,
  //       artistImage: b.image,
  //       price: b.price,
  //     })
  //   );
  //   console.log(line);
  //   setRows(line);
  // }, [bookings]);

  const columns = [
    //   { field: "id", headerName: "ID", width: 60 },
    {
      field: "date",
      headerName: "Date",
      type: "text",
      width: 110,
      editable: true,
    },
    {
      field: "created",
      headerName: "Created",
      type: "text",
      width: 110,
      editable: true,
    },
    {
      field: "artist",
      headerName: "Artist",
      width: 200,
      renderCell: (params) => (
        <div>
          <Tooltip title={params.value.artist} followCursor>
            <span>{params.value && shortText(params.value.artist, 13)}</span>
          </Tooltip>
          <span>
            <Avatar
              alt="Remy Sharp"
              src={params.value && params.value.image}
              sx={{ marginRight: "15px", float: "left", border: "1px solid" }}
            />
          </span>
        </div>
      ),
    },
    // {
    //   field: "artist",
    //   headerName: "Artist",
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   field: "artist",
    //   headerName: "Artist",
    //   valueGetter: (params) => params.line.artist.artist,
    //   width: 150,
    // },
    {
      field: "summary",
      headerName: "Summary",
      type: "button",
      sortable: true,
      renderCell: (getID) => (
        <>
          {/* {console.log("check venuesignature", line[getID.id])} */}
          <Button
            // disabled={line[getID.id].venueSignature !== undefined && line[getID.id].agentSignature !== undefined ? true : false}
            // disabled={!line[getID.id].venueSignature && !line[getID.id].agentSignature ? true : false}
            variant="outlined"
            size="small"
            onClick={
              () => navigate(`/Owner/Summary?bookingid=${line[getID.id].bookingid}`)
              // alert(line[getID.id].bookingid)
            }
          >
            Summary
          </Button>
        </>
      ),
      width: 150,
    },
    {
      field: "rider",
      headerName: "Rider",
      type: "checkbox",
      sortable: false,
      renderCell: (params) => (
        <icon className="text-md-left">
          {params.value ? (
            <FactCheckIcon
              onClick={() => {
                window.open(params.value, "_blank");
              }}
              className="mx-3"
              size={25}
              style={{ color: "#00ffd4" }}
              rel="noreferrer"
            />
          ) : (
            <div style={{ opacity: "0.5" }}>None</div>
          )}
        </icon>
      ),
      width: 80,
    },
    {
      field: "agent",
      headerName: "Contact",
      renderCell: (params) => (
        <div>
          {params.value && params.value.firstName} {params.value && params.value.lastName}
        </div>
      ),
      width: 150,
      editable: true,
    },
    // {
    //   field: "agentEmail",
    //   headerName: "Email",
    //   valueGetter: (params) => params.row.agent.email,
    //   type: "text",
    //   sortable: false,
    //   width: 160,
    // },
    {
      field: "graphA",
      headerName: "Sales",
      type: "checkbox",
      renderCell: (params) => (
        // <>{params.row.tixlyId !== "-" && <EventTicketSummary eventId={params.row.tixlyId} salesPie pieSize="15" noNumber="noNumber" />}</>
        <>
          {params.row.ticketEventId !== "-" && (
            <EventTicketSummary ticketEventId={params.row.ticketEventId} salesPie pieSize="15" noNumber="noNumber" />
          )}
        </>
      ),
      width: 80,
    },
    // {
    //   field: "graphB",
    //   headerName: "Weekly",
    //   type: "checkbox",
    //   renderCell: (params) => <>{params.row.tixlyId !== "-" && <WeeklySalesChart eventId={params.row.ticketEventId} size="small" />}</>,
    //   width: 80,
    // },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      sortable: false,
      width: 80,
    },
    // {
    //   field: "discount",
    //   headerName: "Discount",
    //   type: "checkbox",
    //   sortable: false,
    //   renderCell: (params) => <>{discountSelector(params.row.price, params.row.bookingid)}</>,
    //   width: 160,
    // },
    {
      field: "status",
      headerName: "Status",
      type: "checkbox",
      sortable: true,
      renderCell: (params) => (
        <div
          style={{
            borderRadius: "5px",
            padding: "5px 5px",
            background:
              params.value === "Confirmed"
                ? "00ffd411"
                : params.value === "Awaiting Artist"
                ? "00ffd444"
                : params.value === "Pending Request"
                ? "00ffd477"
                : null,
          }}
        >
          {params.value}
        </div>
      ),
      // renderCell: (params) => {params.value} />,
      width: 150,
    },
    // {
    //   field: "id",
    //   headerName: "Agreement",
    //   type: "checkbox",
    //   sortable: false,
    //   renderCell: (getID) => {
    //     return (
    //       <div>
    //         {/* {getID} */}
    //         {console.log("params value:", line[getID.id])}
    //         {/* {console.log("params value:", line[getID.id].status)} */}
    //         {/* {<AgreementModal bookingid={line[getID.id].bookingid} />} */}
    //         {/* <AgreementModal bookingid={params.value.bookingid} /> */}
    //         {/* {line[params.value].status === "Confirmed" ? (
    //           <AgreementModal bookingid={line[params.value].bookingid} />
    //         ) : line[params.value].status === "Pending Request" ? (
    //           <AgreementModal bookingid={line[params.value].bookingid} sign="venueSignature" />
    //         ) : null} */}
    //       </div>
    //     );
    //   },
    //   width: 80,
    // },
    {
      field: "id",
      headerName: "Contract",
      type: "button",
      sortable: true,
      // renderCell: (getID) => (
      renderCell: (params) => (
        <div>
          {/* <AgreementStatusLink booking={line[getID.id]} /> */}
          <AgreementStatusLink booking={params.row} />
        </div>
        // <div style={{ cursor: "pointer" }}>
        //   {line[getID.id] && line[getID.id].status === "Confirmed" ? (
        //     <BatchAgreementModal agreementId={line[getID.id].agreementId} />
        //   ) : line[getID.id] && line[getID.id].status === "Pending Request" ? (
        //     <BatchAgreementModal sign="venueSignature" agreementId={line[getID.id].agreementId} />
        //   ) : line[getID.id] && line[getID.id].status === "Awaiting Artist" ? (
        //     <BatchAgreementModal agreementId={line[getID.id].agreementId} />
        //   ) : (
        //     "null"
        //   )}
        // </div>
      ),
      width: 150,
    },
    // {
    //   field: "graphB",
    //   headerName: "Tickets",
    //   type: "checkbox",
    //   renderCell: (params) => (
    //     <svg className="newPie" width="40" height="40">
    //       <circle stroke="rgba(255, 185, 0, 0.3)" stroke-dasharray="62.8319, 62.8319" />
    //       {(randy = Math.floor(Math.random() * (100 - 0 + 1)) + 0)}
    //       <circle stroke="rgb(255, 185, 0)" stroke-dasharray={(randy * 62.8319) / 100 + ", 62.8319"} />
    //     </svg>
    //   ),
    //   width: 80,
    // },
  ];

  // Set row background color based on data
  const getRowClassName = (params) => {
    const { row } = params;
    const status = row.status;
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const rowDate = new Date(row.date).setHours(0, 0, 0, 0);

    if (status === "Confirmed") {
      return "confirmed-row";
    } else if (status === "Awaiting Artist") {
      return "awaiting-artist-row";
    } else if (status === "Pending Request") {
      return "pending-request-row";
    } else if (rowDate < currentDate) {
      return "history-row";
    } else {
      return "";
    }
  };

  // React.useEffect(() => {
  //   console.log("🩻 Bookings", bookings);
  //   line = [];
  //   bookings.map(async (booking, id) => {
  //     line.push({
  //       id: id,
  //       ...booking,
  //     });
  //   console.log("🩻 Line", line);
  //   setRows(line);
  // }, [bookings]);

  React.useEffect(() => {
    console.log("🩻 currentVenueBookings", currentVenueBookings);
    line = [];
    currentVenueBookings.map((booking, id) => {
      // line.push({
      //   id: id,
      //   ...booking,
      // });
      line.push({
        ...booking,
        id,
      });
    });
    console.log("🩻 Line", line);
    setRows(line);
  }, [currentVenueBookings]);

  // Filter rows based on switches
  const filteredRows = rows.filter((row) => {
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const rowDate = new Date(row.date).setHours(0, 0, 0, 0);

    if (
      (showConfirmed && row.status === "Confirmed") ||
      (showAwaiting && row.status === "Awaiting Artist") ||
      (showPending && row.status === "Pending Request")
    ) {
      if (showHistory && rowDate < currentDate) {
        return true;
      }
      if (showUpcoming && rowDate >= currentDate) {
        return true;
      }
    }
    return false;
  });

  const handleEditCellChangeCommitted = useCallback(
    ({ id, field, props }) => {
      if (field === "bookingId" || field === "name" || field === "date") {
        const data = props; // contains the new value
        const updatedBookings = bookings.map((booking) => (booking.id === id ? { ...booking, [field]: data.value } : booking));

        setBookings(updatedBookings);
      }
    },
    [bookings, setBookings]
  );

  const handleChangeSettings = (key, value) => {
    console.log(key, value);
    let tmp = { ...cs, [key]: value };
    setTimeout(() => {
      console.log(tmp);
    }, 1000);
    dispatch(storeCurrentSettings(tmp));
  };

  useEffect(() => {
    setShowUpcoming(cs.blUpcoming);
    setShowHistory(cs.blHistory);
    setShowConfirmed(cs.blConfirmed);
    setShowAwaiting(cs.blAwaitingArtist);
    setShowPending(cs.blPendingRequest);
  }, [cs]);

  // const restoreScrollPosition = () => {
  //   // Access the DataGrid API to scroll to the saved position
  //   // This might vary depending on the version of MUI DataGrid you are using
  //   dataGridRef.current?.apiRef.current.scrollToIndexes({
  //     rowIndex: scrollPosition,
  //   });
  // };

  const apiRef = useGridApiRef();
  const handleScroll = () => {
    alert("scroll");
  };
  // useEffect(() => {
  //   downloadDataForBackup(rows);
  // }, [rows]);

  return (
    <Box sx={{ height: "70vh", width: "100%" }}>
      {/* <Button onClick={() => apiRef.current.setPage(1)}>Go to page 1</Button> */}
      <Grid container spacing={4} mb={2}>
        <Grid item>
          <Grid item>
            <FormControlLabel
              control={
                <Switch size="small" checked={cs.blConfirmed} onChange={() => handleChangeSettings("blConfirmed", !cs.blConfirmed)} />
              }
              label="Confirmed"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={cs.blAwaitingArtist}
                  onChange={() => handleChangeSettings("blAwaitingArtist", !cs.blAwaitingArtist)}
                />
              }
              label="Awaiting Artist"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={cs.blPendingRequest}
                  onChange={() => handleChangeSettings("blPendingRequest", !cs.blPendingRequest)}
                />
              }
              label="Pending Requests"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <FormControlLabel
              control={<Switch size="small" checked={cs.blUpcoming} onChange={() => handleChangeSettings("blUpcoming", !cs.blUpcoming)} />}
              label="Upcoming"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Switch size="small" checked={cs.blHistory} onChange={() => handleChangeSettings("blHistory", !cs.blHistory)} />}
              label="History"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button variant="outlined" size="small">
            Email Selected
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        apiRef={apiRef}
        onRowsScrollEnd={handleScroll}
        onViewportRowsChange={handleScroll}
        rows={filteredRows}
        columns={columns}
        // pageSize={100}
        rowsPerPageOptions={[100]}
        checkboxSelection
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
        sortModel={[
          { field: "date", sort: "asc" },
          { field: "status", sort: "asc" },
          { field: "created", sort: "asc" },
        ]}
        getRowClassName={getRowClassName}
        onEditCellChangeCommitted={handleEditCellChangeCommitted}
      />
      {superOwner && (
        <Grid container>
          <ObjectSize dataObject={currentVenueBookings.filter((item) => item.status !== "Killed")} />
          <Button onClick={() => downloadDataForBackup(currentVenueBookings)}>Download all bookings</Button>

          <ObjectSize dataObject={currentVenue} />
          <Button onClick={() => downloadDataForBackup(currentVenue)}>Download Venue</Button>
          {console.log("currentVenue=>", currentVenue)}
        </Grid>
      )}
    </Box>
  );
}
