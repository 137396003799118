/** @format */

import React, { useEffect, useState, useMemo, useRef } from "react";
// import DataFetch from "./DataFetch";
import { useSelector } from "react-redux";
// import "../src/cal.css";
import calendarQuickView from "./calendarQuickView.css";

const CalendarQuickView = () => {
  const [date] = useState(new Date());
  const currentVenueBookings = useSelector((state) => state.counter.currentVenueBookings);
  console.log("currentVenueBookings", currentVenueBookings);
  const [bookedDates, setBookedDates] = useState({
    confirmed: [],
    pending: [],
    killed: [],
    artist: [],
  });

  const [act, setAct] = useState(false);
  const [tog, settog] = useState(false);
  const [popup, setPopup] = useState(null);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const monthRefs = useRef([]);

  useEffect(() => {
    const fetchAndProcessBookings = async () => {
      //   const data = await DataFetch();
      const data = currentVenueBookings;

      const confirmedBookings = data.filter((booking) => booking.status === "Confirmed");
      const pendingBookings = data.filter((booking) => booking.status === "Pending Request");
      const killedBookings = data.filter((booking) => booking.status === "Killed");
      const awaitArtistBookings = data.filter((booking) => booking.status === "Awaiting Artist");

      setBookedDates({
        confirmed: confirmedBookings.map((booking) => booking.date),
        pending: pendingBookings.map((booking) => booking.date),
        killed: killedBookings.map((booking) => booking.date),
        artist: awaitArtistBookings.map((booking) => booking.date),
      });
    };

    fetchAndProcessBookings();
  }, []);

  const toggleAct = () => setAct((prev) => !prev);

  let hoverTimeout;

  const handleButton = () => {
    if (tog === false) {
      settog(true);
      hoverTimeout = setTimeout(() => {
        toggleAct();
      }, 600);
    } else {
      settog(false);
      toggleAct();
    }
  };

  useEffect(() => {
    scrollToCurrentMonth();
  }, [tog]);

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const getDaysArray = (month, year) => Array.from({ length: daysInMonth(month, year) }, (_, i) => i + 1);

  const getMonthData = (month, year) => ({
    month,
    year,
    days: getDaysArray(month, year),
  });

  const generateAllMonths = () => {
    const months = [];
    let currentYear = 2024;
    let currentMonth = 7;

    while (currentYear <= 2028) {
      months.push(getMonthData(currentMonth, currentYear));
      currentMonth++;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear++;
      }
    }
    return months;
  };

  const allMonths = useMemo(() => generateAllMonths(), []);

  const getDayStyle = (dateId) => {
    if (isFilterActive) {
      if (
        !bookedDates.confirmed.includes(dateId) &&
        !bookedDates.pending.includes(dateId) &&
        !bookedDates.killed.includes(dateId) &&
        !bookedDates.artist.includes(dateId)
      ) {
        return { backgroundColor: "lightgray", color: "lightgray" };
      }
      return {};
    }
    if (bookedDates.confirmed.includes(dateId)) {
      return { backgroundColor: "lightgreen", color: "lightgreen" };
    }
    if (bookedDates.pending.includes(dateId)) {
      return { backgroundColor: "yellow", color: "yellow" };
    }
    if (bookedDates.killed.includes(dateId)) {
      return { backgroundColor: "salmon", color: "salmon" };
    }
    if (bookedDates.artist.includes(dateId)) {
      return { backgroundColor: "lightblue", color: "lightblue" };
    }

    return {};
  };

  const handleMouseEnter = (event, dateId) => {
    if (bookedDates.pending.includes(dateId)) {
      const rect = event.target.getBoundingClientRect();
      setPopup({
        content: `${bookedDates.pending.length}: Pending requests Active`,
        position: { top: rect.bottom + window.scrollY, left: rect.left + window.scrollX },
      });
    }
  };

  const handleMouseLeave = () => {
    setPopup(null);
  };

  const renderMonth = (monthData) => {
    const startingWeekday = new Date(monthData.year, monthData.month, 0).getDay();
    const emptyDays = Array.from({ length: startingWeekday }, (_, i) => <div key={`empty-${i}`} className="EmptyDay"></div>);

    return (
      <div className="SMB">
        <h3 className="Bday">
          {new Date(0, monthData.month)
            .toLocaleString("default", { month: "short" })
            .replace(".", "")
            .replace(/^./, (str) => str.toUpperCase())}{" "}
          {monthData.year}
        </h3>
        <div className="dayB">
          <p>M</p>
          <p>Ti</p>
          <p>O</p>
          <p>To</p>
          <p>F</p>
          <p>L</p>
          <p>S</p>
        </div>
        <div className="GridCal">
          {emptyDays}
          {monthData.days.map((day) => {
            const dateId = `${monthData.year}-${String(monthData.month + 1).padStart(2, "0")}-${String(day).padStart(2, "0")}`;

            return (
              <div
                className={`DayBox ${getDayStyle(dateId)}`}
                id={dateId}
                key={dateId}
                style={getDayStyle(dateId)}
                onMouseEnter={(e) => handleMouseEnter(e, dateId)}
                onMouseLeave={handleMouseLeave}
              >
                {day}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const containerClass = `${act ? "BBS" : "BB"} ${tog ? "vis" : "tog"}`;

  const scrollToCurrentMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const targetId = `${currentYear}-${String(currentMonth + 1).padStart(2, "0")}`;
    const targetMonthElement = document.getElementById(targetId);

    if (targetMonthElement) {
      targetMonthElement.scrollIntoView({ behavior: "smooth", inline: "start" });
      window.scrollBy(2 * targetMonthElement.clientWidth, 0);
    } else {
      console.log(`Kunde inte hitta månadselementet med ID: ${targetId}`);
    }
  };

  const handleFilterAvailable = () => {
    setIsFilterActive((prevState) => !prevState);
  };

  return (
    <>
      <button onClick={handleButton}>Kalander</button>
      <div className={containerClass}>
        <div className="MB">
          {allMonths.map((monthData) => (
            <div
              className="SM"
              key={`${monthData.year}-${monthData.month}`}
              ref={(el) => (monthRefs.current[monthData.year + monthData.month] = el)}
              id={`${monthData.year}-${String(monthData.month + 1).padStart(2, "0")}`}
            >
              {renderMonth(monthData)}
            </div>
          ))}
        </div>
        <button onClick={handleFilterAvailable} className="Ledigt">
          {isFilterActive ? "X" : "O"}
        </button>
      </div>
    </>
  );
};

export default CalendarQuickView;
