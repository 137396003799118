/** @format */

import React, { useEffect, useState } from "react";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { Circle, GoogleMap, LoadScript, Marker, OverlayView, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { Grid, Switch, Tooltip } from "@mui/material";
import { darkMapStyles } from "../components/UI helpers/mapStyles";
import { set } from "date-fns";
import ObjectSize from "../helpers/ObjectSize";
import { getDistanceTo } from "../helpers/GetDistanceTo";
import { numberFormatted } from "../helpers/Numbers";
import { calculateEmissionsBasedOnDistance } from "../components/VisualData/TravelEmissionCalculator";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import { wait } from "../components/MiscUIComponents/Wait";
import UpCount from "../components/UI helpers/UpCount";

const EventTravelData = ({ booking, benchmark }) => {
  const [coordinates, setCoordinates] = useState([]);
  const [locations, setLocations] = useState([]);
  const bookingid = booking.bookingid;
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [coordsLoaded, setCoordsLoaded] = useState(false);
  const [showTicketCount, setShowTicketCount] = useState(true);
  const ticketEventId = booking.ticketEventId;
  const ticketVenueId = booking.ticketVenueId;
  const [travelData, setTravelData] = useState([null]);
  const [visitors, setVisitors] = useState([null]);
  const [tooltip, setTooltip] = useState(null);
  const [travelImpact, setTravelImpact] = useState({
    visitors,
    distance: 240,
    climateImpact: 360,
    travel: 480,
    carDistance: 0,
    bicycleDistance: 0,
    busDistance: 0,
    trainDistance: 0,
    planeDistance: 0,
  });

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const googleCX = process.env.REACT_APP_GOOGLE_CX;

  // The center of the map (arbitrary, can be any coordinates)
  const CENTER = {
    lat: 59.3293, // latitude of Stockholm
    lng: 18.0686, // longitude of Stockholm
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleApiKey,
  });

  useEffect(() => {
    async function getPurchases() {
      try {
        let tmp = [];
        const snapshot = await getDocs(
          query(collection(db, `Tickets/${ticketVenueId}/Purchases`), where("ticketEventId", "==", ticketEventId))
        );
        if (snapshot.empty) {
          console.log("No matching documents.");
          return;
        }
        // Process the documents
        snapshot.forEach((doc) => {
          console.log(doc.id, "=>", doc.data());
          tmp.push(doc.data());
        });
        setCoordsLoaded(true);
        setTravelData(tmp);
        console.log("travelData", travelData);
      } catch (error) {
        console.error("Error getting documents: ", error);
      }
    }

    async function getBenchmarkData() {
      console.log("bookingid", bookingid);
      try {
        const docSnap = await getDoc(doc(db, "BenchmarkTravelData", bookingid));
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setTravelData(Object.values(docSnap.data()));
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    }

    // If benchmark is true, get benchmark data, otherwise get purchases
    benchmark ? getBenchmarkData() : getPurchases();
  }, []);

  //   Set visitors, travel distances, and climate impact to the state
  useEffect(() => {
    if (benchmark) return;
    // Visitors
    if (travelData.length < 2) return;
    console.log("travelData", travelData);
    let totalVisitors = 0;
    travelData.forEach((item) => {
      console.log("item", item);
      totalVisitors += item.count ? item.count : 1;
    });
    // setVisitors(totalVisitors);
    setTravelImpact((prev) => ({ ...prev, visitors: totalVisitors }));

    // Travel distances and climateImpact
    let totalDistance = 0;
    let totalClimateImpact = 0;
    let carDistance = 0;
    let bicycleDistance = 0;
    let busDistance = 0;
    let trainDistance = 0;
    let planeDistance = 0;
    const backAndForth = 2;

    travelData.forEach(async (item) => {
      //   const distance = Math.sqrt(Math.pow(item.lat - CENTER.lat, 2) + Math.pow(item.lng - CENTER.lng, 2));
      const numberTraveling = item.count ? item.count : 1;
      const distance = getDistanceTo(CENTER.lat, CENTER.lng, item.lat, item.lng) / 1000; // in km
      const calculatedFromDistance = calculateEmissionsBasedOnDistance(distance);
      const climateImpact = calculatedFromDistance.impact;

      carDistance += (calculatedFromDistance.carDistance * backAndForth) / numberTraveling;
      bicycleDistance += calculatedFromDistance.bicycleDistance * backAndForth * numberTraveling;
      busDistance += calculatedFromDistance.busDistance * backAndForth * numberTraveling;
      trainDistance += calculatedFromDistance.trainDistance * backAndForth * numberTraveling;
      planeDistance += calculatedFromDistance.planeDistance * backAndForth * numberTraveling;

      totalDistance += Number(distance) * backAndForth * numberTraveling;
      totalClimateImpact += Number(climateImpact) * backAndForth * numberTraveling;
    });
    const averageDistanceTraveled = totalDistance / totalVisitors;
    const averageImpact = totalClimateImpact / totalVisitors;
    setTravelImpact((prev) => ({
      ...prev,
      distance: numberFormatted(averageDistanceTraveled),
      climateImpact: numberFormatted(totalClimateImpact),
      averageImpact: numberFormatted(averageImpact),
      carDistance: numberFormatted(carDistance),
      bicycleDistance: numberFormatted(bicycleDistance),
      busDistance: numberFormatted(busDistance),
      trainDistance: numberFormatted(trainDistance),
      planeDistance: numberFormatted(planeDistance),
    }));
  }, [travelData]);

  return (
    <Grid container p={0} xs={12} gap={4} className="eventTravelData">
      {console.log(travelImpact, "travelImpact")}
      {console.log(travelData)}
      {coordsLoaded && console.log("travelData B", travelData)}
      {coordsLoaded && isLoaded && (
        <Grid xs={12}>
          <div style={{ position: "relative" }}>
            <div className="eventTravelDataMap">
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "45vh" }}
                center={CENTER}
                zoom={4}
                options={{ styles: darkMapStyles, disableDefaultUI: true }}
                className="travelDataMap"
              >
                {travelData.map((coord) => (
                  <React.Fragment key={coord.ticketId}>
                    {Array.from({ length: coord.count }).map((_, i) => (
                      <div key={i}>
                        <OverlayView
                          position={{
                            lat: showTicketCount ? coord.lat : parseFloat(coord.lat) + 0.001 * Math.cos(i * 15),
                            lng: showTicketCount ? coord.lng : parseFloat(coord.lng) + 0.001 * Math.sin(i * 15),
                          }}
                          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        >
                          <div
                            className="travelDataPoint"
                            style={{
                              width: "20px",
                              height: "20px",
                              background: "#00ffd455",
                              borderRadius: "50%",
                              textAlign: "center",
                              padding: "3px",
                            }}
                          >
                            {showTicketCount && coord.count}
                          </div>
                        </OverlayView>
                      </div>
                    ))}
                  </React.Fragment>
                ))}
                <Circle
                  center={CENTER}
                  radius={10000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                  }}
                />
                <Circle
                  center={CENTER}
                  radius={20000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                  }}
                />
                <Circle
                  center={CENTER}
                  radius={70000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                  }}
                />
                <Circle
                  center={CENTER}
                  radius={500000} // 10 km in meters
                  options={{
                    fillColor: "blue",
                    fillOpacity: 0.0, // adjust for desired transparency
                    strokeColor: "#00ffd4",
                    strokeOpacity: 0.1, // adjust for desired transparency
                    strokeDashArray: "5 2",
                  }}
                />
                {/* {travelData.map((data, index) => (
              <Marker key={index} position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }} />
            ))} */}
              </GoogleMap>
            </div>
            <div className="travelReport">
              <table className="sustainabilityReport">
                <tr>
                  <td>Travel data</td>
                  <td>
                    <thead>
                      <th>Visitors</th>
                      <th>Average Distance</th>
                      <th>CO2e</th>
                      <th>Average Impact</th>
                    </thead>
                    <tr>
                      <td>
                        {/* <span>{travelImpact.visitors}</span> */}
                        <UpCount target={travelImpact.visitors} type="" />
                      </td>
                      <td>
                        <span>{travelImpact.distance} km</span>
                      </td>
                      <td>
                        <span>{travelImpact.climateImpact} kg</span>
                      </td>
                      <td>
                        <span>{travelImpact.averageImpact} kg</span>
                      </td>
                    </tr>
                    <tr className="headerIcons">
                      <th>
                        <DirectionsCarIcon />
                      </th>
                      <th>
                        <DirectionsBikeIcon />
                      </th>
                      <th>
                        <DirectionsBusIcon />
                      </th>
                      <th>
                        <DirectionsRailwayIcon />
                      </th>
                      <th>
                        <AirplanemodeActiveIcon />
                      </th>
                    </tr>
                    <tr className="travelImpact">
                      <td>
                        <UpCount target={travelImpact.carDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.bicycleDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.busDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.trainDistance} type="km" />
                      </td>
                      <td>
                        <UpCount target={travelImpact.planeDistance} type="km" />
                      </td>

                      {/* <td>{travelImpact.carDistance || 0} km</td>
                      <td>{travelImpact.bicycleDistance || 0} km</td>
                      <td>{travelImpact.busDistance || 0} km</td>
                      <td>{travelImpact.trainDistance || 0} km</td>
                      <td>{travelImpact.planeDistance || 0} km</td> */}
                    </tr>
                  </td>
                  <td>
                    <Switch checked={showTicketCount} onChange={() => setShowTicketCount(!showTicketCount)} />
                  </td>
                </tr>
              </table>
            </div>
          </div>
          {/* <ObjectSize dataObject={travelData} name="Coords size" /> */}
        </Grid>
      )}
    </Grid>
  );
};

export default EventTravelData;
