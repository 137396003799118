/** @format */

import { storeCurrentVenue, storeCurrentVenueBookings } from "../../features/venueFilter/venueFilterSlice";

// These functions can be used to store and retrieve data from IndexedDB on the client side while also fetching data from the server when needed.

//
//
// Create an IndexedDB Database //////////////////////////////////////////////

const dbName = "venuehubDatabase";
// const storeName = "newBookingsStore";

// Make sure the database and object store exist /////////////////////////////////////////////
export const ensureStores = async (storeNames) => {
  const openRequest = indexedDB.open(dbName);

  openRequest.onsuccess = (event) => {
    const db = event.target.result;
    const missingStores = storeNames.filter((store) => !db.objectStoreNames.contains(store));
    if (missingStores.length > 0) {
      db.close();
      deleteDatabase();
      const upgradeRequest = indexedDB.open(dbName, 1);
      // const upgradeRequest = indexedDB.open(dbName, 1);
      upgradeRequest.onupgradeneeded = (event) => {
        const upgradedDB = event.target.result;
        missingStores.forEach((store) => {
          upgradedDB.createObjectStore(store, { keyPath: "bookingid" }); // Adjust keyPath as needed
          console.log(`Object store "${store}" created.`);
        });
      };
      upgradeRequest.onsuccess = () => console.log("Missing stores created successfully.");
      upgradeRequest.onerror = (e) => console.error("Error during upgrade:", e.target.error);
    } else {
      console.log("All required stores already exist.");
    }
  };

  openRequest.onerror = (event) => {
    console.error("Error opening database:", event.target.error);
  };
};

// Open (or create) the database
export function openDatabase(storeName) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);
    // Create the object store if it doesn't exist
    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      console.log("event.target.result", event.target.result);
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "bookingid" }); // Use 'bookingid' as the key
      }
    };

    // const ensureObjectStore = (db, storeName) => {
    //   if (!db.objectStoreNames.contains(storeName)) {
    //     const upgradeRequest = indexedDB.open(db.name, db.version + 1);
    //     upgradeRequest.onupgradeneeded = (event) => {
    //       event.target.result.createObjectStore(storeName, { keyPath: "bookingid" });
    //       console.log(`Object store "${storeName}" created.`);
    //     };
    //     upgradeRequest.onerror = (e) => console.error("Upgrade error:", e.target.error);
    //   }
    // };

    // indexedDB.open(dbName).onsuccess = (event) => {
    //   const db = event.target.result;
    //   ensureObjectStore(db, storeName);
    // };

    request.onsuccess = (event) => {
      resolve(event.target.result);
      console.log("Database opened successfully");
    };
    request.onerror = (event) => {
      reject(event.target.error);
      console.log("Database error:", event.target.error);
    };
  });
}

//
//
// Store Data in IndexedDB //////////////////////////////////////////////
export async function storeDataInIndexedDB(data, storeName) {
  const db = await openDatabase(storeName);
  console.log("data", data);
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    console.log("store", store);
    // store.put(data, data[0].bookingid); // Store the data
    // data.forEach((item, index) => store.put(item, item[index].bookingid)); // Store each item
    data.forEach((item) => store.put(item)); // Store each item

    transaction.oncomplete = () => {
      resolve(true);
      console.log("Data stored successfully", data);
    };
    transaction.onerror = (event) => reject(event.target.error);
  });
}

//
//
// Retrieve Data from IndexedDB //////////////////////////////////////////////
export async function getDataFromIndexedDB(storeName) {
  if (!storeName) return Promise.reject(new Error("Store name is required."));

  try {
    const db = await openDatabase(storeName);
    // if (!db.objectStoreNames.contains(storeName)) throw new Error(`Store "${storeName}" does not exist.`);
    if (!db.objectStoreNames.contains(storeName)) return [];

    const transaction = db.transaction(storeName, "readonly");
    const request = transaction.objectStore(storeName).getAll();

    return new Promise((resolve, reject) => {
      request.onsuccess = () => resolve(request.result);
      request.onerror = () => reject(request.error);
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

// export async function getDataFromIndexedDB(storeName) {
//   const db = await openDatabase(storeName);

//   return new Promise((resolve, reject) => {
//     const transaction = db.transaction(storeName, "readonly");
//     const store = transaction.objectStore(storeName);
//     const request = store.getAll(); // Retrieve all data

//     request.onsuccess = () => {
//       resolve(request.result);
//       alert("success");
//     };
//     request.onerror = (event) => {
//       reject(event.target.error);
//       alert("error");
//     };
//   });
// }

//
//
// Use IndexedDB for Faster Page Load //////////////////////////////////////////////
export async function fetchCachedData(storeName) {
  // alert("trying to fetch");
  // Try to fetch from IndexedDB first
  const cachedData = await getDataFromIndexedDB(storeName);
  if (cachedData.length > 0) {
    // alert("cached data found");
    console.log("Using cached data:", cachedData);

    return cachedData;
  } else {
    // alert("no cached data");
    return [];
  }

  // If no cached data, fetch from the server
  const response = await fetch("/api/currentVenueBookings");
  const serverData = await response.json();

  // Update IndexedDB with server data
  await storeDataInIndexedDB(serverData);
  return serverData;
}

// Example usage
//   fetchCachedData().then((data) => {
//     console.log('Data ready for use:', data);
//   });

//
//
// Handle Updates Automatically //////////////////////////////////////////////
export async function refreshCachedData() {
  const response = await fetch("/api/currentVenueBookings");
  const serverData = await response.json();

  // Update IndexedDB with new data
  await storeDataInIndexedDB(serverData);

  console.log("IndexedDB updated with fresh data:", serverData);
}

// Delete the IndexedDB Database //////////////////////////////////////////////
export function deleteDatabase() {
  let dbName = "venuehubDatabase";
  const deleteRequest = indexedDB.deleteDatabase(dbName);

  deleteRequest.onsuccess = () => {
    console.log(`Database "${dbName}" deleted successfully.`);
  };

  deleteRequest.onerror = (event) => {
    console.error(`Failed to delete database: ${event.target.error}`);
  };

  deleteRequest.onblocked = () => {
    console.warn(`Database deletion blocked. Close any open connections.`);
  };
}
