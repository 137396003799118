/** @format */

import { CircularProgress, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VenueForm from "../components/Forms/VenueForm";
import VenueCalendar from "../components/Sidebar/VenueCalendar";
import PieChartCenteredLabel from "../components/UI helpers/PieChartCenteredLabel";
import UIDrawer from "../components/UI helpers/UIDrawer";
import Drawer from "../components/UI helpers/UIDrawer";
import UpComingEvents from "../features/bookings/UpComingEvents";
import UpComingEventsFlat from "../features/bookings/UpComingEventsFlat";
import MiniMonth from "../features/MiniMonth";
import CreateEditor from "../features/owner/CreateEditor";
import EditorChip from "../features/owner/EditorChip";
import ShowOccupancyData from "../features/owner/ShowOccupancyData";
import { storeCurrentVenue } from "../features/venueFilter/venueFilterSlice";
import ChangeVenue from "../helpers/ChangeVenue";
import Frame1 from "../helpers/Frame1";
import { listenToOwnedVenuesBookings, OwnerVenuesListener, reloadCalendarBookings } from "../helpers/Listeners";
import SelectVenue from "../helpers/SelectVenue";
import ShowCurrentVenue from "../helpers/ShowCurrentVenue";
import VenueCard from "../helpers/VenueCard";
import EditIcon from "@mui/icons-material/Edit";
import AddBookings from "../components/MiscUIComponents/AddBookings";

// const listener = () => {
//   listenToOwnedVenuesBookings();
// };

const OwnerHome = () => {
  const userRole = useSelector((state) => state.counter.userRole);
  const venueAccess = useSelector((state) => state.counter.venueAccess);
  const ownedVenues = useSelector((state) => state.counter.ownedVenues);
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const [ownedVenueIndex, setOwnedVenueIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // function changeVenue = () => {
  //   reloadCalendarBookings(ownedVenues[ownedVenueIndex]);
  // };

  // listenToOwnedVenuesBookings();

  return (
    <div>
      {/* {venueAccess !== "none" ? (
        <OwnerVenuesListener venueid={venueAccess} />
      ) : (
        <div>
          <CircularProgress sx={{ zoom: "0.5" }} />
        </div>
      )} */}
      <Grid container>
        {/* <h1>{ownedVenueIndex}</h1> */}
        <Grid item className="middle" xs={12} sm={9} spacing={4}>
          <Grid item className="venuesContainer" mx={4} mt={2} style={{ height: "1vh" }}>
            {venueAccess !== "none" ? (
              venueAccess.map((venueid, index) => (
                <div
                  onClick={() => {
                    setOwnedVenueIndex(index);
                    console.log(ownedVenues[index]);
                    dispatch(storeCurrentVenue(ownedVenues[index]));
                  }}
                >
                  {/* <Frame1 venueIndex={index} venueid={venueid} /> */}
                </div>
              ))
            ) : (
              <></>
            )}
          </Grid>
          <Grid item px={4}>
            <h5>{currentVenue ? <b>{currentVenue.venue}</b> : <>Choose a Venue above!</>}</h5>
          </Grid>
          <Grid item className="upcomingEventsContainer" style={{ height: "80vh" }}>
            {/* <Grid item className="dateDisplay"></Grid> */}
            {/* <UpComingEventsFlat /> */}
            <VenueCalendar />
          </Grid>
        </Grid>
        <Grid
          item
          className="right"
          xs={12}
          sm={3}
          style={{
            height: "50vh",
          }}
        >
          <Grid
            item
            className="editorsContainer"
            p={3}
            style={{
              height: "20vh",
              // background: "#33333322",
              borderRadius: "10px",
            }}
          >
            <PieChartCenteredLabel
              width="200px"
              height="100px"
              data={[
                { value: 5, label: "Transportation", color: "#00ffd499" },
                { value: 10, label: "Energy use", color: "#00ffd433" },
                { value: 15, label: "Waste", color: "#00ffd455" },
                { value: 20, label: "Water", color: "#00ffd477" },
                { value: 20, label: "Food and Catering", color: "#00ffd4bb" },
              ]}
              label="Emissions"
            />
            {userRole === "admin" && (
              <IconButton onClick={() => navigate("/Sustainability")} style={{ float: "right", opacity: "0.5" }}>
                <EditIcon />
              </IconButton>
            )}

            {/* <CreateEditor />
            {venueAccess !== "none" ? <EditorChip /> : <>loading editors...</>} */}
          </Grid>
          <Grid
            item
            className="statsContainer"
            p={3}
            mt={2}
            style={{
              height: "auto",
              background: "#33333322",
              borderRadius: "10px",
            }}
          >
            <Grid container>
              <Grid item>
                <ShowOccupancyData />
              </Grid>
              <Grid item xs={12} mt={4}>
                <MiniMonth />
              </Grid>
            </Grid>
            <AddBookings currentVenue={currentVenue} />
          </Grid>
        </Grid>
        {/* <UIDrawer content={<VenueForm width="6" />}>
          <VenueForm />
        </UIDrawer> */}
      </Grid>
    </div>
  );
};

export default OwnerHome;

// /** @format */

// import { CircularProgress, Grid, IconButton } from "@mui/material";
// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import VenueForm from "../components/Forms/VenueForm";
// import VenueCalendar from "../components/Sidebar/VenueCalendar";
// import PieChartCenteredLabel from "../components/UI helpers/PieChartCenteredLabel";
// import UIDrawer from "../components/UI helpers/UIDrawer";
// import Drawer from "../components/UI helpers/UIDrawer";
// import UpComingEvents from "../features/bookings/UpComingEvents";
// import UpComingEventsFlat from "../features/bookings/UpComingEventsFlat";
// import MiniMonth from "../features/MiniMonth";
// import CreateEditor from "../features/owner/CreateEditor";
// import EditorChip from "../features/owner/EditorChip";
// import ShowOccupancyData from "../features/owner/ShowOccupancyData";
// import { storeCurrentSettings, storeCurrentVenue } from "../features/venueFilter/venueFilterSlice";
// import ChangeVenue from "../helpers/ChangeVenue";
// import Frame1 from "../helpers/Frame1";
// import { listenToOwnedVenuesBookings, OwnerVenuesListener, reloadCalendarBookings } from "../helpers/Listeners";
// import SelectVenue from "../helpers/SelectVenue";
// import ShowCurrentVenue from "../helpers/ShowCurrentVenue";
// import VenueCard from "../helpers/VenueCard";
// import EditIcon from "@mui/icons-material/Edit";
// import AddBookings from "../components/MiscUIComponents/AddBookings";
// import { set } from "date-fns";

// // const listener = () => {
// //   listenToOwnedVenuesBookings();
// // };

// const OwnerHome = () => {
//   const userRole = useSelector((state) => state.counter.userRole);
//   const venueAccess = useSelector((state) => state.counter.venueAccess);
//   const ownedVenues = useSelector((state) => state.counter.ownedVenues);
//   const currentVenue = useSelector((state) => state.counter.currentVenue);
//   const [ownedVenueIndex, setOwnedVenueIndex] = useState(0);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const cs = useSelector((state) => state.counter.currentSettings);
//   const [selectedMonth, setSelectedMonth] = useState(cs.vcMonth);
//   const [selectedYear, setSelectedYear] = useState(cs.vcYear);

//   // function changeVenue = () => {
//   //   reloadCalendarBookings(ownedVenues[ownedVenueIndex]);
//   // };

//   // listenToOwnedVenuesBookings();
//   // // Swipeable
//   const startX = useRef(0);
//   const endX = useRef(0);

//   // // Swipable
//   const [swipingOpacity, setSwipingOpacity] = useState(1);
//   const [changeOffset, setChangeOffset] = useState(0);
//   const [swipeBlur, setSwipeBlur] = useState(0);

//   const handleTouchStart = (e) => {
//     startX.current = e.touches[0].clientX; // Get the starting X position
//   };

//   const handleTouchMove = (e) => {
//     endX.current = e.touches[0].clientX; // Update the current X position
//     let changeOpacity = Math.min(Math.abs(endX.current - startX.current) / 100, 0.5); // Calculate the opacity change never more than 0.5
//     setSwipeBlur(Math.abs(endX.current - startX.current) / 50);
//     setChangeOffset((endX.current - startX.current) / 5);
//     setSwipingOpacity(1 - changeOpacity);
//   };

//   const handleTouchEnd = () => {
//     const deltaX = endX.current - startX.current; // Calculate the swipe distance
//     setSwipingOpacity(1);
//     setChangeOffset(0);
//     setSwipeBlur(0);
//     if (deltaX > 50) {
//       console.log("Swiped Right");
//       if (selectedMonth === 0) {
//         setSelectedMonth(11);
//         setSelectedYear(selectedYear - 1);
//       } // startover from 11 (december)
//       else setSelectedMonth(selectedMonth - 1);

//       // Handle swipe right action
//     } else if (deltaX < -50) {
//       console.log("Swiped Left");
//       if (selectedMonth === 11) {
//         setSelectedMonth(0);
//         setSelectedYear(selectedYear + 1);
//       } else setSelectedMonth(selectedMonth + 1);

//       // Handle swipe left action
//     }
//   };

//   useEffect(() => {
//     dispatch(storeCurrentSettings({ ...cs, vcMonth: selectedMonth, vcYear: selectedYear }));
//     console.log("dispatching", cs, selectedMonth, selectedYear);
//   }, [selectedMonth, selectedYear]);

//   return (
//     <div>
//       {/* {venueAccess !== "none" ? (
//         <OwnerVenuesListener venueid={venueAccess} />
//       ) : (
//         <div>
//           <CircularProgress sx={{ zoom: "0.5" }} />
//         </div>
//       )} */}
//       <Grid container>
//         {/* <h7>{swipingOpacity}</h7>
//         <div>{swipeBlur}</div> */}
//         {/* <h1>{ownedVenueIndex}</h1> */}
//         <Grid item className="ownerHomeMiddle" xs={12} sm={8} spacing={4}>
//           <Grid item className="venuesContainer" mx={4} mt={2} style={{ height: "1vh" }}>
//             {venueAccess !== "none" ? (
//               venueAccess.map((venueid, index) => (
//                 <div
//                   onClick={() => {
//                     setOwnedVenueIndex(index);
//                     console.log(ownedVenues[index]);
//                     dispatch(storeCurrentVenue(ownedVenues[index]));
//                   }}
//                 >
//                   {/* <Frame1 venueIndex={index} venueid={venueid} /> */}
//                 </div>
//               ))
//             ) : (
//               <></>
//             )}
//           </Grid>
//           <Grid item px={4}>
//             <h5>{currentVenue ? <b>{currentVenue.venue}</b> : <>Choose a Venue above!</>}</h5>
//           </Grid>
//           <Grid
//             item
//             className="upcomingEventsContainer"
//             style={{ height: "80vh" }}
//             onTouchStart={handleTouchStart}
//             onTouchMove={handleTouchMove}
//             onTouchEnd={handleTouchEnd}
//             style={{ transform: `translateX(${changeOffset}px)`, opacity: swipingOpacity, filter: `blur(${swipeBlur}px)` }}
//           >
//             {/* <Grid item className="dateDisplay"></Grid> */}
//             {/* <UpComingEventsFlat /> */}
//             <VenueCalendar />
//           </Grid>
//         </Grid>
//         <Grid
//           item
//           className="right"
//           xs={12}
//           sm={3}
//           style={{
//             height: "50vh",
//           }}
//         >
//           <Grid
//             item
//             className="editorsContainer"
//             p={3}
//             style={{
//               height: "20vh",
//               // background: "#33333322",
//               borderRadius: "10px",
//             }}
//           >
//             <PieChartCenteredLabel
//               width="200px"
//               height="100px"
//               data={[
//                 { value: 5, label: "Transportation", color: "#00ffd499" },
//                 { value: 10, label: "Energy use", color: "#00ffd433" },
//                 { value: 15, label: "Waste", color: "#00ffd455" },
//                 { value: 20, label: "Water", color: "#00ffd477" },
//                 { value: 20, label: "Food and Catering", color: "#00ffd4bb" },
//               ]}
//               label="Emissions"
//             />
//             {userRole === "admin" && (
//               <IconButton onClick={() => navigate("/Sustainability")} style={{ float: "right", opacity: "0.5" }}>
//                 <EditIcon />
//               </IconButton>
//             )}

//             {/* <CreateEditor />
//             {venueAccess !== "none" ? <EditorChip /> : <>loading editors...</>} */}
//           </Grid>
//           <Grid
//             item
//             className="statsContainer"
//             p={3}
//             mt={2}
//             style={{
//               height: "auto",
//               background: "#33333322",
//               borderRadius: "10px",
//             }}
//           >
//             <Grid container>
//               <Grid item>
//                 <ShowOccupancyData />
//               </Grid>
//               <Grid item xs={12} mt={4}>
//                 <MiniMonth />
//               </Grid>
//             </Grid>
//             <AddBookings currentVenue={currentVenue} />
//           </Grid>
//         </Grid>
//         {/* <UIDrawer content={<VenueForm width="6" />}>
//           <VenueForm />
//         </UIDrawer> */}
//       </Grid>
//     </div>
//   );
// };

// export default OwnerHome;
