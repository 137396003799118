/** @format */

// This component is a part of the Nortic integration and is used to set ticket categories for a specific product.

/** @format */

import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axios from "axios";
import shortText from "../../helpers/shortText";
import { storeCurrentProduct, storeCurrentVenue } from "../venueFilter/venueFilterSlice";
import { useDispatch } from "react-redux";
import { set } from "date-fns";
import store from "../../app/store";
import { db } from "../../firebase";
import { doc, updateDoc } from "firebase/firestore";

const SetTicketCategoriesProductNortic = ({ productData, setProductData }) => {
  const currentVenue = useSelector((state) => state.counter.currentVenue);
  const currentProduct = useSelector((state) => state.counter.currentProduct);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: 3236 });
  const [ticketCategories, setTicketCategories] = useState([]); // This holds all ticketCategories with data from Nortic merged with local data
  const [selectedTicketCategories, setSelectedTicketCategories] =
    useState(productData.productTicketCategories && productData.productTicketCategories.map((category) => category.id)) || // This holds the selected ticketCategories array of IDs
    (currentVenue.ticketCategories && currentVenue.ticketCategories) ||
    [];
  // This holds the selected ticketCategories array of IDs
  async function getTicketCategories() {
    setLoading(true);
    // const data = { organizerId: newTicketCategory.organizerId };
    const data = { organizerId: currentVenue.ticketVenueId };
    console.log("Data", data);
    try {
      const result = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicketCategoriesNortic", data);
      const dataFromNortic = result.data.values;
      console.log("dataFromNortic", dataFromNortic);
      const dataFromLocal = productData.productTicketCategories || [];

      //   If no local data is found, set ticketCategories to data from Nortic and selectedTicketCategories to currentVenue.ticketCategories
      if (dataFromLocal.length === 0) {
        setTicketCategories(dataFromNortic);
        setSelectedTicketCategories(currentVenue.ticketCategories);
        return;
      }

      const mergedData = dataFromNortic.map((category) => {
        const localCategory = dataFromLocal.find((item) => item.id === category.id) || null;

        // If local data is found, merge it with data from Nortic
        if (localCategory) {
          return { ...category, ...localCategory };
        } else {
          return category;
        }
      });
      console.log("mergedData", mergedData);
      setTicketCategories(mergedData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // if (!newTicketCategory.organizerId) return;
    getTicketCategories();
    //   }, [newTicketCategory.organizerId]);
  }, []);

  const handleChange = (event) => {
    if (event.target.checked && !selectedTicketCategories.includes(event.target.value)) {
      handleCategoryChange(Number(event.target.value), "selected", !event.target.checked);
      setSelectedTicketCategories([...selectedTicketCategories, Number(event.target.value)]);
    } else {
      handleCategoryChange(Number(event.target.value), "selected", !event.target.checked);
      setSelectedTicketCategories(selectedTicketCategories.filter((category) => category !== Number(event.target.value)));
    }
  };

  const handleCategoryChange = (id, field, value) => {
    // alert(id + " " + field + " " + value);
    const updatedCategories = ticketCategories.map((category) => {
      if (category.id === id) {
        console.log("category.id", id, category.id, selectedTicketCategories.includes(id), selectedTicketCategories);
        return { ...category, [field]: value };
      } else {
        return category;
      }
    });
    console.log("updatedCategories BBBBB", updatedCategories);
    console.log("currentProduct", currentProduct);
    console.log("selectdTicketCategories AAAAA", selectedTicketCategories);

    setTicketCategories(updatedCategories);
    setProductData({ ...productData, productTicketCategories: updatedCategories });
  };

  useEffect(() => {
    console.log("productData CCCC", productData);
  }, [productData]);

  useEffect(() => {
    if (!ticketCategories || (ticketCategories && ticketCategories.length === 0)) return;
    if (!selectedTicketCategories || (selectedTicketCategories && selectedTicketCategories.length === 0)) return;
    const tmpTicketCategories = ticketCategories.filter((category) => selectedTicketCategories.includes(category.id));
    const tmpProductData = { ...productData, productTicketCategories: tmpTicketCategories };
    setProductData(tmpProductData);
  }, [selectedTicketCategories, ticketCategories]);

  useEffect(() => {
    getTicketCategories();
  }, [currentVenue]);

  return (
    <div style={{ display: "flex-wrap" }}>
      {console.log("currentProduct", currentProduct)}
      {console.log("productData", productData)}
      <h7>Ticket categories for {currentProduct.product}</h7>
      <FormControlLabel
        label="Edit Mode"
        control={<Switch checked={editMode} onChange={() => setEditMode(!editMode)} />}
        style={{ marginLeft: "55px" }}
      />
      <FormGroup
      //  onChange={(event) => handleChange(event)}
      >
        {ticketCategories &&
          ticketCategories.length &&
          ticketCategories
            .sort((a, b) => a.name.localeCompare(b.name, "sv"))
            .map((category) => (
              <>
                {(editMode || selectedTicketCategories.includes(category.id)) && (
                  <Grid container key={category.id} spacing={2} mt={2}>
                    {/* Category Label with Switch */}
                    <Grid
                      item
                      xs={12} // Full width on extra-small screens
                      sm={6} // Takes 6 columns (50%) on small screens
                      md={4} // Takes 4 columns (33.33%) on medium screens
                    >
                      <FormControlLabel
                        label={category.name}
                        control={
                          <Switch
                            checked={selectedTicketCategories.includes(category.id)}
                            value={category.id}
                            onChange={(event) => handleChange(event)}
                          />
                        }
                      />
                    </Grid>

                    {/* Conditional Fields */}
                    {selectedTicketCategories.includes(category.id) && (
                      <>
                        <Grid item xs={12} md={4}>
                          <TextField
                            label="Price"
                            size="small"
                            value={ticketCategories.find((item) => item.id === category.id).price || 0}
                            onChange={(e) => handleCategoryChange(category.id, "price", Number(e.target.value))}
                            sx={{
                              background: !ticketCategories.find((item) => item.id === category.id).price && "orange",
                              borderRadius: "10px",
                            }}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} md={4}>
                          <TextField
                            label="Max Available"
                            size="small"
                            value={ticketCategories.find((item) => item.id === category.id).maxAvailable || 0}
                            onChange={(e) => handleCategoryChange(category.id, "maxAvailable", Number(e.target.value))}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justifyContent="flex-end" spacing={2}>
                            <Grid item xs={12} md={4}>
                              <TextField label="Description" size="small" fullWidth />
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <TextField label="Max per customer" size="small" fullWidth />
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Divider />
                  </Grid>
                )}
              </>
            ))}
      </FormGroup>

      {console.log("selectedTicketCategories", selectedTicketCategories)}
    </div>
  );
};

export default SetTicketCategoriesProductNortic;

/** @format */

// This component is a part of the Nortic integration and is used to set ticket categories for a specific product.

/** @format */

// import {
//   Autocomplete,
//   Box,
//   Button,
//   ButtonGroup,
//   Checkbox,
//   CircularProgress,
//   Divider,
//   FormControlLabel,
//   FormGroup,
//   Grid,
//   IconButton,
//   Radio,
//   RadioGroup,
//   Switch,
//   TextField,
//   ToggleButton,
//   ToggleButtonGroup,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import axios from "axios";
// import shortText from "../../helpers/shortText";
// import { storeCurrentProduct, storeCurrentVenue } from "../venueFilter/venueFilterSlice";
// import { useDispatch } from "react-redux";
// import { set } from "date-fns";
// import store from "../../app/store";
// import { db } from "../../firebase";
// import { doc, updateDoc } from "firebase/firestore";

// const SetTicketCategoriesProductNortic = ({ productData, setProductData, type }) => {
//   const currentVenue = useSelector((state) => state.counter.currentVenue);
//   const currentProduct = useSelector((state) => state.counter.currentProduct);
//   const [loading, setLoading] = useState(false);
//   const [editMode, setEditMode] = useState(false);
//   const [newTicketCategory, setNewTicketCategory] = useState({ organizerId: 3305 });
//   const [ticketCategories, setTicketCategories] = useState([]); // This holds all ticketCategories with data from Nortic merged with local data
//   const [selectedTicketCategories, setSelectedTicketCategories] =
//     useState(productData.productTicketCategories && productData.productTicketCategories.map((category) => category.id)) || // This holds the selected ticketCategories array of IDs
//     (currentVenue.ticketCategories && currentVenue.ticketCategories) ||
//     [];
//   // This holds the selected ticketCategories array of IDs
//   async function getTicketCategories() {
//     setLoading(true);
//     // const data = { organizerId: newTicketCategory.organizerId };
//     const data = { organizerId: currentVenue.ticketVenueId };
//     console.log("Data", data);
//     try {
//       const result = await axios.post("http://127.0.0.1:5001/venuehub-64e72/europe-west3/getTicketCategoriesNortic", data);
//       const dataFromNortic = result.data.values;
//       console.log("dataFromNortic", dataFromNortic);
//       const dataFromLocal = productData.productTicketCategories || [];

//       //   If no local data is found, set ticketCategories to data from Nortic and selectedTicketCategories to currentVenue.ticketCategories
//       if (dataFromLocal.length === 0) {
//         setTicketCategories(dataFromNortic);
//         setSelectedTicketCategories(currentVenue.ticketCategories);
//         return;
//       }

//       const mergedData = dataFromNortic.map((category) => {
//         const localCategory = dataFromLocal.find((item) => item.id === category.id) || null;

//         // If local data is found, merge it with data from Nortic
//         if (localCategory) {
//           return { ...category, ...localCategory };
//         } else {
//           return category;
//         }
//       });
//       console.log("mergedData", mergedData);
//       setTicketCategories(mergedData);
//     } catch (error) {
//       console.log(error);
//     } finally {
//       setLoading(false);
//     }
//   }

//   useEffect(() => {
//     // if (!newTicketCategory.organizerId) return;
//     getTicketCategories();
//     //   }, [newTicketCategory.organizerId]);
//   }, []);

//   const handleChange = (event) => {
//     if (event.target.checked && !selectedTicketCategories.includes(event.target.value)) {
//       setSelectedTicketCategories([...selectedTicketCategories, Number(event.target.value)]);
//     } else {
//       setSelectedTicketCategories(selectedTicketCategories.filter((category) => category !== Number(event.target.value)));
//     }
//   };

//   const handleCategoryChange = (id, field, value) => {
//     const updatedCategories = ticketCategories.map((category) => {
//       if (category.id === id && selectedTicketCategories.includes(category.id)) {
//         console.log("category.id", id, category.id, selectedTicketCategories.includes(id), selectedTicketCategories);
//         return { ...category, [field]: value };
//       } else {
//         return category;
//       }
//     });
//     console.log("updatedCategories BBBBB", updatedCategories);
//     console.log("currentProduct", currentProduct);
//     console.log("selectdTicketCategories AAAAA", selectedTicketCategories);

//     setTicketCategories(updatedCategories);
//     setProductData({ ...productData, productTicketCategories: updatedCategories });
//   };

//   useEffect(() => {
//     console.log("productData CCCC", productData);
//   }, [productData]);

//   useEffect(() => {
//     if (!ticketCategories || (ticketCategories && ticketCategories.length === 0)) return;
//     if (!selectedTicketCategories || (selectedTicketCategories && selectedTicketCategories.length === 0)) return;
//     const tmpTicketCategories = ticketCategories.filter((category) => selectedTicketCategories.includes(category.id));
//     const tmpProductData = { ...productData, productTicketCategories: tmpTicketCategories };
//     setProductData(tmpProductData);
//   }, [selectedTicketCategories, ticketCategories]);

//   useEffect(() => {
//     getTicketCategories();
//   }, [currentVenue]);

//   return (
//     <div style={{ display: "flex-wrap" }}>
//       {console.log("currentProduct", currentProduct)}
//       {console.log("productData", productData)}
//       <h7>Ticket categories for {currentProduct.product}</h7>
//       <FormControlLabel
//         label="Edit Mode"
//         control={<Switch checked={editMode} onChange={() => setEditMode(!editMode)} />}
//         style={{ marginLeft: "55px" }}
//       />
//       <FormGroup onChange={(event) => handleChange(event)}>
//         {ticketCategories &&
//           ticketCategories.length &&
//           ticketCategories
//             .sort((a, b) => a.name.localeCompare(b.name, "sv"))
//             .map((category) => (
//               <>
//                 {(editMode || selectedTicketCategories.includes(category.id)) && (
//                   <Grid container key={category.id} spacing={2} mt={2}>
//                     {/* Category Label with Switch */}
//                     <Grid
//                       item
//                       xs={12} // Full width on extra-small screens
//                       sm={6} // Takes 6 columns (50%) on small screens
//                       md={4} // Takes 4 columns (33.33%) on medium screens
//                     >
//                       <FormControlLabel
//                         label={category.name}
//                         control={<Switch checked={selectedTicketCategories.includes(category.id)} value={category.id} />}
//                       />
//                     </Grid>

//                     {/* Conditional Fields */}
//                     {selectedTicketCategories.includes(category.id) && (
//                       <>
//                         <Grid item xs={12} md={4}>
//                           <TextField
//                             label="Price"
//                             size="small"
//                             value={ticketCategories.find((item) => item.id === category.id).price || 0}
//                             onChange={(e) => handleCategoryChange(category.id, "price", Number(e.target.value))}
//                             sx={{
//                               background: !ticketCategories.find((item) => item.id === category.id).price && "orange",
//                               borderRadius: "10px",
//                             }}
//                             fullWidth
//                           />
//                         </Grid>

//                         <Grid item xs={12} md={4}>
//                           <TextField
//                             label="Max Available"
//                             size="small"
//                             value={ticketCategories.find((item) => item.id === category.id).maxAvailable || 0}
//                             onChange={(e) => handleCategoryChange(category.id, "maxAvailable", Number(e.target.value))}
//                             fullWidth
//                           />
//                         </Grid>
//                         <Grid item xs={12}>
//                           <Grid container justifyContent="flex-end" spacing={2}>
//                             <Grid item xs={12} md={4}>
//                               <TextField label="Description" size="small" fullWidth />
//                             </Grid>

//                             <Grid item xs={12} md={4}>
//                               <TextField label="Max per customer" size="small" fullWidth />
//                             </Grid>
//                           </Grid>
//                         </Grid>
//                       </>
//                     )}
//                     <Divider />
//                   </Grid>
//                 )}
//               </>
//             ))}
//       </FormGroup>

//       {console.log("selectedTicketCategories", selectedTicketCategories)}
//     </div>
//   );
// };

// export default SetTicketCategoriesProductNortic;
