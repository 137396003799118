/** @format */

import React, { useEffect, useState } from "react";
import {
  AreaChart,
  LineChart,
  BarChart,
  Area,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";
// import { ticketSales } from "../ANGELO/ticketSales"; // Importera din data

const GraphForTicketSales = ({
  //   data = ticketSales, // Data to use
  data,
  ticketEventId = "ahp82um6vk22fwh", // Default event ID
  Formate1 = "created", // expected field for x-axis
  Formate2 = "count", // expected field for y-axis
  lineColor = "#03fcdf", // Line color
  circleColor = "transparent", // Dot color
  lineLabel = "Amount", // Label for the line data
  xAxisLabel = "", // Label for the X-axis
  yAxisLabel = "Tickets", // Label for the Y-axis
  backgroundColor = "", // Background color for grid and lines
  maxYAxisValue = 2000, // Max value for the Y-axis
  width = 300, // Width of the chart
  height = 200, // Height of the chart
  chartType = "bar", // välj din graf (area, line, bar)
}) => {
  const [eventData, setEventData] = useState({});
  const [dailyData, setDailyData] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [chartMaxYAxisValue, setChartMaxYAxisValue] = useState(maxYAxisValue);

  const formatMonth = (month) => {
    const date = new Date(month + "-01");
    return date.toLocaleString("default", { month: "short" });
  };

  useEffect(() => {
    const groupedData = data.reduce((acc, item) => {
      const eventId = item.ticketEventId;
      const month = new Date(item[Formate1]).toISOString().slice(0, 7);
      if (!acc[eventId]) {
        acc[eventId] = {};
      }
      if (!acc[eventId][month]) {
        acc[eventId][month] = 0;
      }
      acc[eventId][month] += item[Formate2];
      return acc;
    }, {});

    const extendedData = {};
    Object.keys(groupedData).forEach((eventId) => {
      const eventStart = new Date(data.find((e) => e.ticketEventId === eventId).eventStart);
      const sortedMonths = Object.keys(groupedData[eventId]).sort();
      const firstMonth = new Date(sortedMonths[0] + "-01");
      const lastMonth = new Date(sortedMonths[sortedMonths.length - 1] + "-01");
      const allMonths = [];
      let currentMonth = new Date(firstMonth);
      currentMonth.setMonth(currentMonth.getMonth() - 1);

      while (currentMonth <= eventStart) {
        const monthString = currentMonth.toISOString().slice(0, 7);
        allMonths.push(monthString);
        currentMonth.setMonth(currentMonth.getMonth() + 1);
      }

      extendedData[eventId] = {};
      let runningTotal = 0;

      allMonths.forEach((month) => {
        runningTotal += groupedData[eventId][month] || 0;
        extendedData[eventId][month] = runningTotal;
      });
    });

    setEventData(extendedData);
  }, [data, Formate1, Formate2]);

  const handleMonthClick = (month) => {
    const dailyGroupedData = data.reduce((acc, item) => {
      const eventId = item.ticketEventId;
      const date = new Date(item[Formate1]);
      const day = date.getDate();
      const dataMonth = date.toISOString().slice(0, 7);

      if (eventId === ticketEventId && dataMonth === month) {
        acc[day] = (acc[day] || 0) + item[Formate2];
      }
      return acc;
    }, {});

    setDailyData(dailyGroupedData);
    setSelectedMonth(month);

    const maxValue = Math.max(...Object.values(dailyGroupedData));
    setChartMaxYAxisValue(maxValue);
  };

  const handleBackToMonths = () => {
    setSelectedMonth(null);
    setChartMaxYAxisValue(maxYAxisValue);
  };

  const CustomDot = (props) => {
    const { cx, cy, payload } = props;
    const month = payload.name;

    return (
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={circleColor}
        stroke="#fff"
        strokeWidth={0.5}
        onClick={() => {
          if (!selectedMonth) {
            const clickedMonth = Object.keys(eventData[ticketEventId] || {}).find((m) => formatMonth(m) === month);
            if (clickedMonth) handleMonthClick(clickedMonth);
          }
        }}
        style={{ cursor: "pointer" }}
      />
    );
  };

  const chartData = selectedMonth
    ? Object.keys(dailyData).map((day) => ({ name: `${day} ${formatMonth(selectedMonth)}`, [lineLabel]: dailyData[day] }))
    : Object.keys(eventData[ticketEventId] || {}).map((month) => ({
        name: formatMonth(month),
        [lineLabel]: eventData[ticketEventId][month],
      }));

  const renderChart = () => {
    switch (chartType) {
      case "line":
        return (
          <LineChart data={chartData}>
            <CartesianGrid stroke={backgroundColor} />
            <XAxis
              dataKey="name"
              label={{ value: xAxisLabel, position: "insideBottom", offset: 0 }}
              tickFormatter={(tick) => {
                if (selectedMonth) {
                  const [day, month] = tick.split(" ");
                  return `${day} ${month}`;
                } else {
                  return formatMonth(tick);
                }
              }}
            />
            <YAxis label={{ value: yAxisLabel, angle: -90, position: "insideLeft" }} domain={[0, chartMaxYAxisValue]} />
            <Tooltip />
            <Line type="monotone" dataKey={lineLabel} stroke={lineColor} dot={<CustomDot />} activeDot={null} />
          </LineChart>
        );
      case "bar":
        return (
          <BarChart data={chartData}>
            <CartesianGrid stroke={backgroundColor} />
            <XAxis
              dataKey="name"
              label={{ value: xAxisLabel, position: "insideBottom", offset: 0 }}
              tickFormatter={(tick) => {
                if (selectedMonth) {
                  const [day, month] = tick.split(" ");
                  return `${day} ${month}`;
                } else {
                  return formatMonth(tick);
                }
              }}
            />
            <YAxis label={{ value: yAxisLabel, angle: -90, position: "insideLeft" }} domain={[0, chartMaxYAxisValue]} />
            <Tooltip />
            <Bar dataKey={lineLabel} fill={lineColor}>
              {chartData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={lineColor}
                  onClick={() => {
                    if (!selectedMonth) {
                      const clickedMonth = Object.keys(eventData[ticketEventId] || {}).find((m) => formatMonth(m) === entry.name);
                      if (clickedMonth) handleMonthClick(clickedMonth);
                    }
                  }}
                  style={{ cursor: "pointer" }}
                />
              ))}
            </Bar>
          </BarChart>
        );
      case "area":
      default:
        return (
          <AreaChart data={chartData}>
            <CartesianGrid stroke={backgroundColor} />
            <XAxis
              dataKey="name"
              label={{ value: xAxisLabel, position: "insideBottom", offset: 0 }}
              tickFormatter={(tick) => {
                if (selectedMonth) {
                  const [day, month] = tick.split(" ");
                  return `${day} ${month}`;
                } else {
                  return formatMonth(tick);
                }
              }}
            />
            <YAxis label={{ value: yAxisLabel, angle: -90, position: "insideLeft" }} domain={[0, chartMaxYAxisValue]} />
            <Tooltip />
            <Area type="monotone" dataKey={lineLabel} stroke={lineColor} fill={lineColor} dot={<CustomDot />} activeDot={null} />
          </AreaChart>
        );
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {selectedMonth && (
        <button
          onClick={handleBackToMonths}
          style={{
            position: "absolute",
            bottom: 11,
            left: 10,
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            padding: "15px",
            borderRadius: "50%",
            fontSize: "15px",
            zIndex: 10,
          }}
        >
          ←
        </button>
      )}
      <ResponsiveContainer width={width} height={height}>
        {renderChart()}
      </ResponsiveContainer>
    </div>
  );
};

export default GraphForTicketSales;
